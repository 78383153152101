
// import { api } from "../../Components/api_instance";
import { WithTranslation, withTranslation } from "react-i18next";
import { EmployeeViewComponent } from "./Components/EmployeeViewElement";
import { Component } from "react";
import { EmployeeEvent, EmployeeExtended, Message, STATUS, getApiInstance } from "../../Api/ApiDefinition";
import EmployeeLoadingComponent from "./Components/EmployeeLoading";


class EmployeeViewPage extends Component<EmployeePageProps, EmployeePageState> {
	api = getApiInstance();

	constructor(props: EmployeePageProps) {
		super(props);
		this.state = {
			sampleUser: null,
			sampleFaltas: [],
			allCalendarEvents: [],
			sampleComms: [],
			loading: true
		};
	}

	async componentDidMount() {
		//
		try {
			const [user, userEvents, calendarEvents, messages] = await Promise.all([
				this.api.getEmployee(1),
				this.api.getEmployeeWeekEvents(1),
				this.api.getEmployeeEvents(1),
				this.api.getMessages(false, "", 1, 5),
			]);
			this.setState({
				sampleUser: user.status === STATUS.OK ? user.data : null,
				sampleFaltas: userEvents.status === STATUS.OK ? userEvents.data : [],
				allCalendarEvents: calendarEvents.status === STATUS.OK ? [] : [],
				//allCalendarEvents: calendarEvents.status === STATUS.OK ? calendarEvents.data : [],
				sampleComms: messages.status === STATUS.OK ? messages.data.slice(0, 5) : [],
				loading: false
			});
			// Now you can use the results of the three API calls
			
		} catch (error) {
			// Handle errors
			console.error("Error in one or more API calls", error);
		}

	}

	handleEmployeeUpdate = (updatedEmployee: EmployeeExtended) => {
		this.setState({ sampleUser: updatedEmployee });
		// You can perform additional actions or update other parts of the state
	};
	render() {
		const t = this.props.t;
		return (
			<section className="pageContent">
				<div className="page-header">
					<h2>{t("employeeViewPage.title")}</h2>
					<ol className="breadcrumb">
						<li className="active">{t("employeeViewPage.title")}</li>
					</ol>
				</div>
				
				{this.state.loading ? <EmployeeLoadingComponent></EmployeeLoadingComponent> : 
					this.state.sampleUser ? (<EmployeeViewComponent employee={this.state.sampleUser} faltas={this.state.sampleFaltas} messages={this.state.sampleComms} allCalendarEvents={this.state.allCalendarEvents} onEmployeeUpdate={this.handleEmployeeUpdate} />)
					: <p>Error </p>}
			</section>
		);
	}
}




interface EmployeePageProps extends WithTranslation {
	
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- TODO Remove when state is complete, remove if no state is used
interface EmployeePageState {

	sampleUser: EmployeeExtended | null;
	sampleFaltas: EmployeeEvent[];
	allCalendarEvents: EmployeeEvent[];
	sampleComms: Message[];
	loading: boolean;
 }

export default withTranslation()(EmployeeViewPage);
