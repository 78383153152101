import { Component, ChangeEvent, createRef } from "react";
import { Shift } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class EmployeeEventEdit extends Component<EmployeeEventEditProps, EmployeeEventEditState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: EmployeeEventEditProps) {
        super(props);
        this.state = { eventChange: this.props.event };
    }
    //TODO Somehow expose eventChange to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const name = e.target.name as
            "startEdited"
        | "endEdited"
        | "notes";
        
        let eventChange = this.props.event;
        if (value != "") {
            eventChange = {
                ...eventChange,
                [name]: value
            };
        }

        this.setState({ eventChange: eventChange });
    }



    componentDidUpdate(prevProps: EmployeeEventEditProps) {
        if (this.props.showEmployeeEditEventForm !== prevProps.showEmployeeEditEventForm) this.updateShown();
    }
    updateShown() {
        if (this.props.showEmployeeEditEventForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showEmployeeEditEventForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        if (this.props.showEmployeeEditEventForm == false) { return null; }
        return (
            <>
                <h1 className="head">Edit Event</h1>
                <div className="body" style={{ flex: 1 }}>
                    <form  >
                        {this.UserInput("startEdited")}
                        <hr />
                        {this.UserInput("endEdited")}
                        <hr />
                        {this.UserInput("notes")}
                    </form>

                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.updateEmployeeEvent(this.state.eventChange)}>Save Changes</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof Shift) {
        const eventChange = this.state.eventChange;
        const event = this.props.event;
        let value = (eventChange[field] ?? event[field]) as unknown as string;	//TODO validate field instead

        let filedType = "time";
        if (field == "notes"){
            filedType = "text";
        }else{
            value = this.timestampToInputTime(value);
        }
        return (
            <div>
                <label className="label bold">{field}</label>
                <input className="text-muted" value={value} name={field} type={filedType} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
    timestampToInputTime(timestamp: string): string {
        console.log(timestamp);
        const date = new Date(timestamp); // Create a Date object from the timestamp
        console.log(date);

        const hours = String(date.getHours()).padStart(2, "0"); // Get hours and pad with leading zero if necessary
        const minutes = String(date.getMinutes()).padStart(2, "0"); // Get minutes and pad with leading zero if necessary
        return `${hours}:${minutes}`; // Format hours and minutes as HH:MM
    }
}
interface EmployeeEventEditProps {
    // user: EmployeeExtended;
    showEmployeeEditEventForm: boolean;
    event: Partial<Shift>;
    onDismiss: () => void;
    updateEmployeeEvent: (eventChange: Partial<Shift>) => void;
    
    //updateUser: (user: Partial<EmployeeExtended>) => void;

}
interface EmployeeEventEditState { eventChange: Partial<Shift>; }
