import { Component } from "react";
import { ScheduleSummarized, getApiInstance } from "../../Api/ApiDefinition";
import { mdiPencil, mdiViewWeekOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { ScheduleEditForm } from "./components/ScheduleEdit";
import { ScheduleAddForm } from "./components/ScheduleAdd";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

export class ScheduleListComponent extends Component<ScheduleListComponentProps, ScheduleListComponentState> {
	api = getApiInstance();

	constructor(props: ScheduleListComponentProps) {
		super(props);
		this.state = {
			showScheduleEditForm: false,
			showScheduleAddForm: false,
			schedule: {} as ScheduleSummarized
		};
	}

	row(schedule: ScheduleSummarized) {
		return (
			<tr key={schedule.id}>
				<td>{schedule.name}</td>
				<td>{schedule.weeklyWorkHours}</td>
				<td>
					<button title="Edit" onClick={() => this.openEditForm(schedule)} className="toggleButton mr1">
						<Icon
							size={1}
							path={mdiPencil}
						/>
					</button>
					<Link to={"/scheduleView?id=" + schedule.id.toString()}>
						<button className="toggleButton" title="View" ><Icon path={mdiViewWeekOutline} size={1} /></button>
					</Link>
					</td>
			</tr>
		);
	}
	openEditForm(schedule: ScheduleSummarized) {
		this.setState({ showScheduleEditForm: true, schedule: schedule });
	}

	
	updateSchedule = (schedule: ScheduleSummarized,  id: number) =>{
		
		this.api.postSchedule(id, schedule).then((data) => {
			console.log(data);
			this.setState({ showScheduleEditForm: false });
		}).catch((error) => {
			console.log(error);
			this.setState({ showScheduleEditForm: false });
		});
		
		//console.log("test");
		
	};
	addSchedule = (schedule: ScheduleSummarized, id: number) => {
		this.api.postSchedule(id, schedule).then((data) => {
			console.log(data);
			this.setState({ showScheduleAddForm: false });
		}).catch((error) => {
			console.log(error);
			this.setState({ showScheduleAddForm: false });
		});

		//console.log("test");

	};
	render() {
		
		return (
			<div className="pt1 overflowx">
				<div className="table-header">
					<h3>Schedule</h3><button title="Add Schedule" className="btn btn-primary" onClick={() => this.setState({ showScheduleAddForm: true })}>Add Schedule</button>
				</div>
				<table className="commonTable mt1">
				<thead>
					<tr>
						<th>schedule.name</th>
						<th>schedule.weeklyWorkHours</th>
						<th>schedule.actions</th>
					</tr>
				</thead>
					{this.props.loading ? (
						<tbody>
							<tr className="skeleton"><td colSpan={3} className="text-center">Loading...</td></tr>
						</tbody>
					) : (
							<tbody>
								{this.props.schedules.map((cal) => this.row(cal))}
							</tbody>
					)}
				
			</table>
			<ScheduleEditForm showScheduleEditForm={this.state.showScheduleEditForm} schedule={this.state.schedule} onDismiss={() => this.setState({ showScheduleEditForm: false })} updateSchedule={this.updateSchedule} ></ScheduleEditForm>
			<ScheduleAddForm showScheduleAddForm={this.state.showScheduleAddForm} onDismiss={() => this.setState({ showScheduleAddForm: false })} addSchedule={this.addSchedule} ></ScheduleAddForm>

			</div>
		);
	}
}

interface ScheduleListComponentProps {
	schedules: ScheduleSummarized[];
	loading: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- TODO Remove when state is complete, remove if no state is used
interface ScheduleListComponentState {
	showScheduleEditForm: boolean;
	showScheduleAddForm: boolean;
	schedule: ScheduleSummarized
}
