import { Component, ChangeEvent } from "react";
import { EmployeeExtended } from "../../../Api/ApiDefinition";
import { isVisible } from "@testing-library/user-event/dist/utils";

export class UserDataForm extends Component<UserDataFormProps, UserDataFormState>{
	constructor(props: UserDataFormProps) {
		super(props);
		this.state = { userChanges: {}, isVisible: false };
		console.log("Emails:", this.props.user.emails);

	}
	//TODO Somehow expose userChanges to the parent component

	handleChange(e: ChangeEvent<HTMLInputElement>) {
		const value = e.target.value;
		const name = e.target.name as 
			"prefix"
			| "name"
			| "surname"
			| "suffix"
			// | "calendarId"
			// | "telephones"
			// | "emails"
			| "address"
			| "notes";


		const userChanges = this.state.userChanges;
		if (this.props.user[name] === value) delete userChanges[name];
		else userChanges[name] = value;

		this.setState({ userChanges });
		
	}

	render() {
		return (
			<div className="card mb-xs-1" style={{ flex: 1 }}>
				<form className="body" >
					<div className="row">
						{this.UserInput("name", "col6")}
						{this.UserInput("surname", "col6")}
					</div>
					<div className="row">
						{this.UserInput("prefix", "col6")}
						{this.UserInput("suffix", "col6")}
					</div>
					{this.state.isVisible && <div >
						<div className="row">
							<div className="col12">
								<label className="label bold">Calendar: </label>
								<label>{this.props.user.calendar ? this.props.user.calendar.name : ""}</label>
							</div>
						</div>
						<div className="row">
							<div className="col12">
								<label className="label bold">Tags: </label>
								<label>{this.props.user.tags.length > 0 ? this.props.user.tags.join(", ") : ""}</label>
							</div>
						</div>
						{this.props.user.emails.map((email) => (
							<div className="row">
								<div className="col12" >
									<label className="label bold">{email.kind} Email: </label>
									<label>{email.email}</label>
								</div>
							</div>
						))}
						{this.props.user.telephones.map((telephone) => (
							<div className="row">

								<div className="col12" >
									<label className="label bold">{telephone.kind} Phone: </label>
									<label>{telephone.number}</label>
								</div>

							</div>
						))}
						<div className="row">
							{this.UserInput("address", "col12")}
						</div>
						<div className="row">
							{this.UserInput("notes", "col12")}
						</div>
					</div>
					}
					<div className="row" style={{ cursor: "pointer", textAlign: "center", justifyContent: "flex-end" }} onClick={this.toggleVisibility}> {this.state.isVisible ? "Hide More Information" : "Show More Information" }</div>
										
				</form>
				<button className="btn" onClick={this.props.showEmplyeeEditForm}>Edit</button>
			</div>
		);
	}
	toggleVisibility = () => {
		this.setState({
			isVisible: !this.state.isVisible
		});
	};
	UserInput(field: keyof EmployeeExtended, styleClass: string) {
		const userChanges = this.state.userChanges;
		const user = this.props.user;
		const value = (userChanges[field] ?? user[field]) as unknown as string;	

		return (
			<div className={styleClass} >
				<label className="label bold">{field}: </label>
				<label>{value}</label>

			</div>
		);

	}
}
interface UserDataFormProps { 
	user: EmployeeExtended; 
	showEmplyeeEditForm: () => void;
}
interface UserDataFormState { userChanges: Partial<EmployeeExtended>; isVisible: boolean; }
