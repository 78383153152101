import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

export const defaultNS = "translation";

void i18next
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(HttpApi)
	.init({
		debug: true,
		fallbackLng: "es",
		lng: "es", // if you're using a language detector, do not define the lng option

		defaultNS,

		interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		}
	});

export default i18next;
