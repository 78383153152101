
import { Message } from "../../../Api/ApiDefinition";
import { EmployeeMessagesTable } from "./EmployeeMessagesTable";
import { WithTranslation, withTranslation } from "react-i18next";
import { STATUS, getApiInstance } from "../../../Api/ApiDefinition";
import { Component, createRef } from "react";


class EmployeeListMessages extends Component<EmployeeListMessagesComponentProps, EmployeeListMessagesComponentState> {
    api = getApiInstance();
    dialogRef = createRef<HTMLDialogElement>();
    
    constructor(props: EmployeeListMessagesComponentProps) {
        super(props);

        this.state = {
            messages: [],
            showArchived: false,
            searchValue: "",
            pageNumber: 1,
            perPage: 10,
            currentItems: [],

        };
    }

    componentDidMount() {
        void this.queryMessages({ keyword: "" });
    }
    componentDidUpdate(prevProps: EmployeeListMessagesComponentProps) {
        if (this.props.showEmployeeListMessages !== prevProps.showEmployeeListMessages) this.updateShown();
    }
    updateShown() {
        if (this.props.showEmployeeListMessages != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showEmployeeListMessages == false) {
            this.dialogRef.current?.close();
        }
    }
    async queryMessages({ keyword = "" }) {
        try {
            const items = await this.api.getMessages(this.state.showArchived, keyword, this.state.pageNumber, this.state.perPage);
            if (items.status !== STATUS.OK)
                this.setState({ messages: [] });
            else
                this.setState({
                    messages: items.data,
                    pageNumber: 1, // Reset to the first page when new items are fetched
                    currentItems: items.data.slice(0, this.state.perPage),
                });
        } catch (error) {
            console.error("Error archiving message:", error);
            // Optionally, update the UI to reflect the error state
        }

    }
    toggleArchiived() {
        this.setState({ showArchived: !this.state.showArchived }, () => {
            void this.queryMessages({ keyword: "" });
        });

    }
    handlePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newPerPage = parseInt(event.target.value, 10);
        this.setState({ perPage: newPerPage, pageNumber: 1 }, () => {
            void this.queryMessages({ keyword: this.state.searchValue.length >= 3 ? this.state.searchValue : "" });
        });


    };
    handleSeach = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({ searchValue: value }, () => {
            if (value.length >= 3) {
                void this.queryMessages({ keyword: value });
            } else if (value.length == 0) {
                void this.queryMessages({ keyword: "" });
            }
        });
    };
    async setMessageArchived(msg: Message) {
        try {
            const items = await this.api.setMessageArchived(msg.id, !msg.archived);
            if (items.status !== STATUS.OK) {
                throw new Error("Failed to archive message");
            } else {
                const rowElement = document.getElementById(`row-${msg.id}`);
                if (rowElement) {
                    rowElement.classList.add("row-exit-active");
                }
                setTimeout(() => {
                    this.setState(prevState => ({
                        messages: prevState.messages.filter(m => m.id !== msg.id)
                    }));
                }, 500);
            }
        } catch (error) {
            console.error("Error archiving message:", error);
            // Optionally, update the UI to reflect the error state
        }

    }
    handleNextPage = () => {
        const { pageNumber, perPage, messages } = this.state;
        const nextPage = pageNumber + 1;
        const startIdx = (nextPage - 1) * perPage;
        const endIdx = startIdx + perPage;
        const nextPageItems = messages.slice(startIdx, endIdx);

        if (nextPageItems.length > 0) {
            this.setState({
                pageNumber: nextPage,
                currentItems: nextPageItems,
            });
        }
    };
    handlePrevPage = () => {
        const { pageNumber, perPage, messages } = this.state;
        const prevPage = Math.max(1, pageNumber - 1);
        const startIdx = (prevPage - 1) * perPage;
        const endIdx = startIdx + perPage;
        const prevPageItems = messages.slice(startIdx, endIdx);

        this.setState({
            pageNumber: prevPage,
            currentItems: prevPageItems,
        });
    };

   

    render() {
        
        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        const t = this.props.t;
        
        const maxPage = Math.ceil(this.state.messages.length / this.state.perPage);
        if (this.props.showEmployeeListMessages == false) { return null; }

        
        return (
             <>
                <h1 className="head">{t("EmployeeListMessages.title")}</h1>
                <div className="body">
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                   
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ marginRight: 10 }}>
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            value={this.state.searchValue}
                                            onChange={this.handleSeach}
                                        />
                                    </div>
                                    <div>
                                        <select name="page" id="page" value={this.state.perPage} onChange={this.handlePageSize}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <EmployeeMessagesTable messages={this.state.currentItems}  onClickArchive={(msg) => void this.setMessageArchived(msg)} />
                            <div className="pagination">
                                <button onClick={this.handlePrevPage} disabled={this.state.pageNumber === 1}>
                                    {t("EmployeeListMessages.previousPage")}
                                </button>
                                <span> {t("EmployeeListMessages.page")} {this.state.pageNumber} </span>
                                <button onClick={this.handleNextPage} disabled={this.state.pageNumber === maxPage}>{t("EmployeeListMessages.nextPage")}</button>
                            </div>

                        </div>
                </div >
                <div className="footer">
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }

   
}

interface EmployeeListMessagesComponentProps extends WithTranslation {
    showEmployeeListMessages: boolean;
    onDismiss: () => void;
}
type EmployeeListMessagesComponentState = {
    messages: Message[];
    showArchived: boolean;
    searchValue: string;
    pageNumber: number;
    perPage: number;
    currentItems: Message[];

};

export default withTranslation()(EmployeeListMessages);
