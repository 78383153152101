import { Component, ChangeEvent, createRef } from "react";
import { ScheduleSummarized, Shift } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class ScheduleAddConfirm extends Component<ScheduleAddConfirmProps, ScheduleAddConfirmState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: ScheduleAddConfirmProps) {
        super(props);
        this.state = { id: 0 };
    }


    componentDidUpdate(prevProps: ScheduleAddConfirmProps) {
        if (this.props.scheduleAddConfirm !== prevProps.scheduleAddConfirm) this.updateShown();
    }
    updateShown() {
        if (this.props.scheduleAddConfirm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.scheduleAddConfirm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        if (this.props.scheduleAddConfirm == false) { return null; }
        return (
            <>
                <h1 className="head">Confirm Schdule</h1>
                <div className="body" style={{ flex: 1 }}>
                    <p>Are you sure want to add Schdule as mention below?</p>
                    {
                        this.props.schedules.filter((schedule) => schedule.id == this.props.scheduleId).map((schedule) => (
                            <div style={{ flex: 1, margin: "20px" }}>
                                <p><b>Name: </b>{schedule.name}</p>
                                <p><b>Start Date: </b>{this.props.startDate} </p>
                                <p><b>End Date: </b>{this.props.endDate} </p>
                                <p><b>Weekly Working Hours: </b>{schedule.weeklyWorkHours}</p>
                            </div>
                        ))}

                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.addScheduleCalendar(this.state.id)}>Save</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }

}
interface ScheduleAddConfirmProps {
    scheduleAddConfirm: boolean;
    scheduleId: number;
    onDismiss: () => void;
    addScheduleCalendar: (id: number) => void;
    schedules: ScheduleSummarized[];
    startDate: string;
    endDate: string;
}
interface ScheduleAddConfirmState {
    id: number
}
