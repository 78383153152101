
import React from "react";
import "./index.scss";


type Props = Record<string, never>;

type State = Record<string, never>;
export default class Scheduler extends React.Component<Props, State>{
	constructor(public props: Props) {
		super(props);
	}

	render() {
		return (
			<div className="tableGrid tablaPeriodosSemana horizontal"></div>
		);
	}
}
