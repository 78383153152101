import { Component, ChangeEvent, createRef } from "react";
import { ScheduleSummarized, Shift } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class ScheduleAdd extends Component<ScheduleAddProps, ScheduleAddState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: ScheduleAddProps) {
        super(props);
        this.state = { id: 0 };
    }


    componentDidUpdate(prevProps: ScheduleAddProps) {
        if (this.props.scheduleAddForm !== prevProps.scheduleAddForm) this.updateShown();
    }
    updateShown() {
        if (this.props.scheduleAddForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.scheduleAddForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        if (this.props.scheduleAddForm == false) { return null; }
        return (
            <>
                <h1 className="head">Add Schdule</h1>
                <div className="body" style={{ flex: 1 }}>
                    {
                        this.props.schedules.map((schedule) => (
                            <div style={{ flex: 1, margin: "20px" }} onClick={() => this.setState({ id: schedule.id })}>
                               <input type="radio" name="schedule" value={schedule.id} checked={this.state.id == schedule.id}  /> {schedule.name}
                            </div>
                        ))
                    }

                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.addScheduleSelect(this.state.id)}>Add Schedule</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    
}
interface ScheduleAddProps {
    scheduleAddForm: boolean;
    onDismiss: () => void;
    addScheduleSelect: (id: number) => void;
    schedules: ScheduleSummarized[];

}
interface ScheduleAddState { 
    id: number
 }
