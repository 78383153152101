import { Component, ChangeEvent, createRef } from "react";
import {  EmployeeExtended } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class EmployeeEditForm extends Component<EmployeeEditFormProps, EmployeeEditFormState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: EmployeeEditFormProps) {
        super(props);
        this.state = { userChanges: {} };
    }
    //TODO Somehow expose userChanges to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const name = e.target.name as
            "prefix"
            | "name"
            | "surname"
            | "suffix"
            | "prefix"
            | "address"
            | "notes";


        const userChanges = this.state.userChanges;
        if (this.props.user[name] === value) delete userChanges[name];
        else userChanges[name] = value;

        this.setState({ userChanges });
    }

    

    componentDidUpdate(prevProps: EmployeeEditFormProps) {
        if (this.props.showEmployeeForm !== prevProps.showEmployeeForm) this.updateShown();
    }
    updateShown() {
        if (this.props.showEmployeeForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showEmployeeForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {

        if (this.props.showEmployeeForm == false) { return null; }

        return (
            <>
                <h1 className="head">Edit Employee</h1>
                <div className="body" style={{ flex: 1 }}>
                        <form  >
                            {this.UserInput("name")}
                            <hr />
                            {this.UserInput("surname")}
                            <hr />

                            {this.UserInput("suffix")}
                            <hr />
                            {this.UserInput("prefix")}
                            <hr />
                            {this.UserInput("address")}
                            <hr />
                            {this.UserInput("notes")}
                        </form>
                        
                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.updateUser(this.state.userChanges)}>Save Changes</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof EmployeeExtended) {
        const userChanges = this.state.userChanges;
        const user = this.props.user;
        const value = (userChanges[field] ?? user[field]) as unknown as string;	//TODO validate field instead

        return (
            <div>
                <label className="label bold">{field}</label>
                <input className="text-muted" value={value} name={field} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
}
interface EmployeeEditFormProps { 
    user: EmployeeExtended;
    showEmployeeForm: boolean;
    onDismiss: () => void;
    updateUser: (user: Partial<EmployeeExtended>) => void;

 }
interface EmployeeEditFormState { userChanges: Partial<EmployeeExtended>; }
