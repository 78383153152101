import NavigationMenu from "../NavigationMenu/NavigationMenu";
import styles from "./Header.module.scss";
import { useState } from "react";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";

export default function Header() {
	const [isShownPreview, setIsShownPreview] = useState(false);
	const [isShownClick, setIsShownClick] = useState(false);
	const [isShownHover, setIsShownHover] = useState(false);

	document.addEventListener("click", () => setIsShownHover(false));

	return (
		<header className={styles.topHeader}>
			<div className="navigation">
				<div className="navigationTrigger"
					onMouseEnter={() => setIsShownPreview(true)}
					onMouseLeave={() => setIsShownPreview(false)}
					onClick={() => setIsShownClick(!(isShownClick || isShownHover))}
				>
					<Icon path={mdiMenu} size={1.5} />
				</div>
				<div onMouseEnter={() => setIsShownHover(true)} onClick={() => setIsShownClick(!(isShownClick || isShownHover))}>
					<NavigationMenu className={isShownHover ? "show" : isShownClick ? "showClick" : isShownPreview ? "showPreview" : ""} />
				</div>
				<div onClick={() => setIsShownClick(false)} className={`dismissClicker ${isShownClick ? "activeClick" : ""}`}></div>
			</div>
			<div className={styles.logo}>
				CONTROL DE PRESENCIA
			</div>
			<div className={`${styles.clock} ${styles.centerItem}`}  >
				<span>2:28 PM</span>
			</div>
			<div></div>
			<div className={styles.currentUser} >
				Administrador
			</div>
		</header>
	);
}