import { Component, ReactNode, KeyboardEvent } from "react";
import { EmployeeBasicInfo, Tag } from "../../Api/ApiDefinition";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class EmployeeListComponent extends Component<EmployeeListComponentProps, EmployeeListComponentState> {

	constructor(props: EmployeeListComponentProps) {
		super(props);
		this.state = {
			selectedRows: [], // Keep track of selected rows
			isCtrlKeyPressed: false, // To check if the Control key is pressed
			allRowsSelected: false
		};
	}
	handleRowClick = (itemId: EmployeeBasicInfo, type: string) => {
		const { selectedRows, isCtrlKeyPressed } = this.state;
		let updatedSelectedRows: EmployeeBasicInfo[];

		if ((isCtrlKeyPressed && type === "row") || type == "checkbox") {
			// Toggle selection on Ctrl + Click
			if (selectedRows.includes(itemId)) {
				updatedSelectedRows = selectedRows.filter((id) => id !== itemId);
			} else {
				updatedSelectedRows = [...selectedRows, itemId];
			}
			
			if (updatedSelectedRows.length === this.props.users.length) {
				this.setState({ allRowsSelected: true });
			}else{
				this.setState({ allRowsSelected: false });
			}
			this.setState({ selectedRows: updatedSelectedRows });
			this.props.handleRowClick(updatedSelectedRows);
			
		}

	};
	
	handleHeaderCheckboxChange = () => {
		this.setState((prevState) => {
			const allRowsSelected = !prevState.allRowsSelected;

			const updatedSelectedRows = allRowsSelected
				? this.props.users.map((item) => item)
				: [];
				
			this.props.handleRowClick(updatedSelectedRows);

			return {
				allRowsSelected,
				selectedRows: updatedSelectedRows,
			};
		});
	};

	handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		
		const isMac = /Mac/.test(navigator.platform);

		if (isMac) {
			if (event.key === "Meta") {
				this.setState({ isCtrlKeyPressed: true });
			}
		} else {
			if (event.key === "Control") {
				this.setState({ isCtrlKeyPressed: true });
			}
		}
	};

	handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
		const isMac = /Mac/.test(navigator.platform);

		if (isMac) {
			if (event.key === "Meta") {
				this.setState({ isCtrlKeyPressed: false });
			}
		} else {
			if (event.key === "Control") {
				this.setState({ isCtrlKeyPressed: false });
			}
		}
	};
	render(): ReactNode {
		const t = this.props.t;
		const renderMessageRows = () => {
			if (this.props.users.length > 0) {
				return this.props.users.map((item) => this.row(item));
			} else {
				return <tr><td colSpan={4} className="text-center">No Record found</td></tr>;
			}
		};
		return (
			<div tabIndex={0} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp}> 
				<table className="commonTable">
					<thead>
						<tr>
							<th><input type="checkbox"
								checked={this.state.allRowsSelected}
								onChange={this.handleHeaderCheckboxChange} /></th>
							<th>{t("employeeListPage.employeeTable.fullName")}</th>
							<th>{t("employeeListPage.employeeTable.tags")}</th>
							<th>{t("employeeListPage.employeeTable.calendar")}</th>
						</tr>
					</thead>
					{
						this.props.loading ? (
							<tbody>
								<tr className="skeleton"><td colSpan={4} className="text-center">Loading...</td></tr>
							</tbody>
						)
							: (
								<tbody>
									{renderMessageRows()}
								</tbody>
							)
					}
					
				</table>
				
			</div>
			
		);
	}

	static getFullName(item: EmployeeBasicInfo) {
		const prefix = item.prefix != "" ? item.prefix + " - " : "";
		const suffix = item.suffix != "" ? " - " + item.suffix : "";

		return `${prefix}${item.name} ${item.surname} ${suffix}`;
	}
	tagsDisply = (tags: Tag[]) => {
		return tags.map((tag) => {
			return `<div class="chip">${tag.text}</div>`;
		}).join("");
	};
	row(item: EmployeeBasicInfo) {
		
		return (
			
			<tr key={item.id}
				onClick={() => this.handleRowClick(item, "row")}
				style={{ cursor: this.state.isCtrlKeyPressed ? "cell" : "pointer" }}
                >
				<td><input type="checkbox" checked={this.state.selectedRows.includes(item)}
					onChange={(event) => event.stopPropagation()}
					onClick={() => this.handleRowClick(item, "checkbox")}  /></td>
				<td ><Link to={"/employeeView?id=" + item.id.toString()}>{EmployeeListComponent.getFullName(item)}</Link></td>
				<td  dangerouslySetInnerHTML={{ __html: this.tagsDisply(item.tags) }}></td>
				<td >{item.calendar.name}</td>
			</tr>
		);
	}

}

interface EmployeeListComponentProps extends WithTranslation {
	users: EmployeeBasicInfo[];
	handleRowClick: (selectedRows: EmployeeBasicInfo[]) => void;
	loading: boolean;

}
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- TODO Remove when state is complete, remove if no state is used
interface EmployeeListComponentState {
	selectedRows: EmployeeBasicInfo[];
	isCtrlKeyPressed: boolean;
	allRowsSelected: boolean;
 }


export default withTranslation()(EmployeeListComponent);