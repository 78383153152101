import { Component, ChangeEvent, createRef } from "react";
import { CalendarBasicInfo } from "../../../Api/ApiDefinition";

export class CalendarAddForm extends Component<CalendarAddFormProps, CalendarAddFormState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: CalendarAddFormProps) {
        super(props);
        this.state = { calendarChanges: {
            name: "",
            id: 0
        } };
    }
    //TODO Somehow expose calendarChanges to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const name = e.target.name as "name";
        const calendarChanges = { ...this.state.calendarChanges }; // Create a shallow copy
        console.log(calendarChanges);
        calendarChanges[name] = value;
        this.setState({ calendarChanges });
    }



    componentDidUpdate(prevProps: CalendarAddFormProps) {
        if (this.props.showCalendarAddForm !== prevProps.showCalendarAddForm) this.updateShown();
    }
    updateShown() {
        if (this.props.showCalendarAddForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showCalendarAddForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {

        if (this.props.showCalendarAddForm == false) { return null; }


        return (
            <>
                <h1 className="head">Add Calendar</h1>
                <div className="body">
                    <div style={{ flex: 1 }}>
                        <form className="body" >
                            {this.UserInput("name")}
                           
                        </form>

                    </div>
                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.addCalendar(this.state.calendarChanges, 0)}>Save Changes</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof CalendarBasicInfo) {
        const calendarChanges = this.state.calendarChanges;
        const value = (calendarChanges[field]) as unknown as string;	//TODO validate field instead

        return (
            <div>
                <label className="label bold">{field}</label>
                <input className="text-muted" value={value} name={field} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
}
interface CalendarAddFormProps {
    showCalendarAddForm: boolean;
    onDismiss: () => void;
    addCalendar: (calendar: CalendarBasicInfo, id: number) => void;

}
interface CalendarAddFormState { calendarChanges: CalendarBasicInfo; }
