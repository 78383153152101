import { Component, createRef } from "react";
import { ListItemWithIcon } from "../../../Components/ListItemWithIcon";
import styles from "../index.module.scss";
import { Employee, EmployeeEvent, EmployeeExtended, Message, getApiInstance } from "../../../Api/ApiDefinition";
import { MonthCard } from "./EmployeeViewMonthCard";
import { UserDataForm } from "./EmployeeViewCardInfoForm";
import { mdiAccountOff, mdiAlert, mdiCalendarCheck, mdiGavel, mdiHelpCircle, mdiPlus } from "@mdi/js";
import EmployeeListMessages from "./EmployeeListMessages";
import EmployeeUpdatePhoto from "./EmployeeUpdatePhoto";
import { EmployeeEditForm } from "./EmployeeEdit";
import { EmployeeAbsences } from "./EmployeeAbsences";

const defaultProfilePic = "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp";

export class EmployeeViewComponent extends Component<EmployeeViewComponentProps, EmployeeViewComponentState> {
	api = getApiInstance();
	dialogRef = createRef<HTMLDialogElement>();

	constructor(props: EmployeeViewComponentProps) {
		super(props);

		this.state = {
			userChanges: {},
			showEmployeeListMessages: false,
			showEmployeeUpdatePhoto: false,
			showEmployeeForm: false,
			showEmployeeAbsences: false,
			// Initialize other state properties here
		};
	}
	render() {
		const { faltas, messages, employee } = this.props;
		// api.getShiftById(1).then(setResult).catch(console.error);

		return (
			<section className={styles.Profile}>
				<div style={{ flex: 1 }} className={styles.subSection}>
					
					<UserCard employee={employee} viewAbsences={() => this.setState({ showEmployeeAbsences: true })} updateUserStatus={this.updateUserStatus} showUpdateImageModal={() => this.setState({ showEmployeeUpdatePhoto: true })} />
					<UserEventsRecent events={faltas} />
					<UserCommsRecent comms={messages} viewCommunications={this.viewAllCommunications} />
					

				</div>

				<div style={{ flex: 2 }} className={styles.subSection}>
					<div style={{ display: "flex", gap: "20px" }}>
						<UserDataForm user={employee} showEmplyeeEditForm={() => this.setState({ showEmployeeForm: true })} />
					</div>

					<MonthCard user={employee} allCalendarEvents={this.props.allCalendarEvents} />
				</div>
				<EmployeeListMessages showEmployeeListMessages={this.state.showEmployeeListMessages} onDismiss={() => this.setState({ showEmployeeListMessages: false })} />
				<EmployeeUpdatePhoto showEmployeeUpdatePhoto={this.state.showEmployeeUpdatePhoto} updatedPhoto={this.updateUserPhoto} onDismiss={() => this.setState({ showEmployeeUpdatePhoto: false })} />
				<EmployeeEditForm user={employee} showEmployeeForm={this.state.showEmployeeForm} updateUser={this.updateUser} onDismiss={() => this.setState({ showEmployeeForm: false })} />
				<EmployeeAbsences user={employee} showEmployeeAbsences={this.state.showEmployeeAbsences} onDismiss={() => this.setState({ showEmployeeAbsences: false })}></EmployeeAbsences>
			</section>
		);
	}
	updateUser = (user: Partial<EmployeeExtended>) => {
		//
		this.api.patchEmployee(this.props.employee.id, user).then(
			() => {
				const updatedEmployee = { ...this.props.employee, ...user };
				this.props.onEmployeeUpdate(updatedEmployee);

				//this.props.employee = updatedEmployee;
				this.setState({ userChanges: updatedEmployee, showEmployeeForm: false });
				alert("Status Updated");
			}
		).catch(console.error);
	};
	updateUserStatus = (status : boolean) => {
		console.log( status);
		this.api.patchEmployee(this.props.employee.id, {"status": status}).then(
			() => {
				this.props.employee.status = status;
				alert("Status Updated");
			}
		).catch(console.error);

	};
	updateUserPhoto = (image: string) => {
		this.api.patchEmployee(this.props.employee.id, { "image": image }).then(
			() => {
				this.setState({ showEmployeeUpdatePhoto: false });
				this.props.employee.image = image;
				alert("Image Updated");
			}
		).catch(console.error);

	};
	
	viewAllCommunications = () => {
		this.setState({ showEmployeeListMessages: true });
	};
	

	
}

interface EmployeeViewComponentProps {
	employee: EmployeeExtended;
	faltas: EmployeeEvent[];
	allCalendarEvents: EmployeeEvent[];
	messages: Message[];
	onEmployeeUpdate: (employee: EmployeeExtended) => void;

}
interface EmployeeViewComponentState {
	userChanges: Partial<Employee>;
	showEmployeeListMessages: boolean;
	showEmployeeUpdatePhoto:boolean;
	showEmployeeForm: boolean;
	showEmployeeAbsences: boolean;
}


const UserCard = (props: { employee: EmployeeExtended, viewAbsences: EmptyCallback, updateUserStatus: (isChecked: boolean) => void, showUpdateImageModal: EmptyCallback }) => {
	const employee = props.employee;

	const fullName = `${employee.prefix} - ${employee.name} ${employee.surname}${employee.suffix}`;
	const employeeImage = employee.image ?? defaultProfilePic;
	const { worked, vacations } = props.employee.hourData;

	return (

		<div className="card mb-xs-1">
			<div className="body text-center">
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div><h1>{fullName}</h1></div>
					<div style={{ display: "flex", alignItems: "center" }}>
						In Active&nbsp;&nbsp;<label className="switch">
							<input 
								onChange={(e) => props.updateUserStatus(e.target.checked)}
								type="checkbox"></input>
							<span className="slider round"></span>
						</label>&nbsp;&nbsp;Active
					</div>
				</div>

				<img onClick={props.showUpdateImageModal}  src={employeeImage} alt="" className="rounded-circle" style={{ width: "150px" }}  />
				<p className="">Vacaciones Disfrutadas/Asignadas/Anuales: {vacations.used}/{vacations.assigned}/{vacations.yearly}</p>
				<p className="">Horas Imputadas/Esperadas/Anuales: {worked.done}/{worked.expected}/{worked.yearly}</p>
				<p className=""><button className="link" onClick={props.viewAbsences}>Faltas Contabilidazas: 5</button></p>
			</div>
		</div>
	);
};

const UserEventsRecent = (props: { events: EmployeeEvent[]; }) => (
	<div className="card mb-xs-1">
		<div className="head">
			Eventos Cercanos
		</div>
		<ul className="body customMarkers">
			{props.events.map(UserEvent)}
		</ul>
	</div>
);
const UserEvent = (falta: EmployeeEvent) => {
	const style: React.CSSProperties = {};
	if (falta.estado === "PendienteIntervencion") { style.fontWeight = "bold"; style.color = "red"; }

	const formatter = new Intl.DateTimeFormat("es-ES", {});

	const startStr = formatter.format(falta.start * 1000);
	const endStr = formatter.format(falta.end * 1000);

	return (
		<ListItemWithIcon key={falta.id} icon={IconosTiposEvento[falta.categoria]} iconColor={ColoresEstadoEvento[falta.estado]}>
			<div style={style}>
				<code>{startStr}-{endStr}: </code>
				<span>{falta.categoria}</span>
			</div>
		</ListItemWithIcon>
	);
};

const UserCommsRecent = (props: { comms: Message[]; viewCommunications: EmptyCallback; }) => (
	<div className="card mb-xs-1">
		<div className="head">
			Comunicaciones Recientes
			<button onClick={props.viewCommunications} className="btn btn-primary" style={{ float: "right" }}>Ver Todas</button>
		</div>
		<ul className="body">
			{props.comms.map((comm, index) => (
				<li key={index}>
					<div>{comm.body}</div>
				</li>
			))}
		</ul>
	</div>
);




export const IconosTiposEvento: Record<EmployeeEvent["categoria"], string> = {
	"Vacaciones": mdiCalendarCheck,
	"Otros": mdiHelpCircle,
	"Pendiente": mdiAlert,
	"Deber Inexcusable": mdiGavel,
	"Asuntos Propios": mdiAccountOff,
	"Baja Medica": mdiPlus
};

export const ColoresEstadoEvento: Record<EmployeeEvent["estado"], string> = { "Autorizada": "green", "No autorizada": "purple", "PendienteIntervencion": "red" };