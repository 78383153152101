import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // needed for dayClick

import { CalendarSchedule, CalendarSummarized, STATUS, ScheduleSummarized, getApiInstance } from "../../Api/ApiDefinition";
import { WithTranslation, withTranslation } from "react-i18next";
import { Component } from "react";
import { ScheduleAdd } from "./Components/AddSchedule";
import { ScheduleAddConfirm } from "./Components/ScheduleAddConfirm";
import { UpdateCalendar } from "./Components/UpdateCalendar";
import { DayCellContentArg, DayCellMountArg } from "@fullcalendar/core";
import React from "react";
import styles from "./index.module.scss";

class CalendarViewPage extends Component<CalendarListComponentProps, CalendarListComponentState> {
	api = getApiInstance();
	calendarRef = React.createRef<FullCalendar>();

	constructor(props: CalendarListComponentProps) {
		super(props);

		this.state = {
			summary: {
				work: 1864,
				holiday: 12 * 8,
				vacations: 120,
			},
			schedules: [],
			scheduleAddForm: false,
			selectable: false,
			scheduleAddConfirm: false,
			scheduleId: 0,
			startDate: "",
			endDate: "",
			updateCalendarForm: false,
			updateType: ""
		};
	}
	handleDateClick = (arg: DateClickArg) => {
		if(this.state.selectable){
			
			this.calendarRef.current?.getApi().select(new Date(this.state.startDate),arg.dateStr);
			this.setState({ scheduleAddConfirm: true,  selectable: false, startDate: this.state.startDate, endDate: arg.dateStr,  });
			
		}else{
			const clickedDate = arg.date;
			const eventsOnDate = arg.view.calendar.getEvents();
			const eventsOnClickedDate = eventsOnDate.filter(event => {
				const eventStartDate = new Date(event.startStr);
				const eventEndDate = new Date(event.endStr);
				const eventStartDateOnly = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate());
				const eventEndDateOnly = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate());

				const clickedDateOnly = new Date(clickedDate.getFullYear(), clickedDate.getMonth(), clickedDate.getDate());
				return eventStartDateOnly <= clickedDateOnly && eventEndDateOnly >= clickedDateOnly;
			});
			if (eventsOnClickedDate.length > 0) {
				const typeHoliday = eventsOnClickedDate.filter(event => {
					return event.extendedProps.type === "holiday";
				});
				const typeVacation = eventsOnClickedDate.filter(event => {
					return event.extendedProps.type === "vacation";
				});
				if (typeHoliday.length > 0) {
					this.setState({ updateCalendarForm: true, updateType: "holiday" });
				} else if (typeVacation.length > 0) {
					const eventStartDate = new Date(typeVacation[0].startStr);
					const eventEndDate = new Date(typeVacation[0].endStr);
					eventEndDate.setDate(eventEndDate.getDate() - 1);

					const eventStartDateOnly = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate());
					const eventEndDateOnly = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate());

					const clickedDateOnly = new Date(clickedDate.getFullYear(), clickedDate.getMonth(), clickedDate.getDate());
					if (eventStartDateOnly.getTime() == clickedDateOnly.getTime()) {
						this.setState({ updateCalendarForm: true, updateType: "vacationstart" });
					} else if (clickedDateOnly.getTime() == eventEndDateOnly.getTime()) {
						this.setState({ updateCalendarForm: true, updateType: "vacationend" });

					} else {
						this.setState({ updateCalendarForm: true, updateType: "vacationmid" });
					}


				} else {
					const eventStartDate = new Date(eventsOnClickedDate[0].startStr);
					const eventEndDate = new Date(eventsOnClickedDate[0].endStr);
					eventEndDate.setDate(eventEndDate.getDate() - 1);

					const eventStartDateOnly = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate());
					const eventEndDateOnly = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate());
					const clickedDateOnly = new Date(clickedDate.getFullYear(), clickedDate.getMonth(), clickedDate.getDate());

					if (eventStartDateOnly.getTime() == clickedDateOnly.getTime()) {
						this.setState({ updateCalendarForm: true, updateType: "schedulestart" });
					} else if (clickedDateOnly.getTime() == eventEndDateOnly.getTime()) {
						this.setState({ updateCalendarForm: true, updateType: "scheduleend" });

					} else {
						this.setState({ updateCalendarForm: true, updateType: "schedulemid" });
					}
				}
				//
			} else {
				this.setState({ scheduleAddForm: true, startDate: clickedDate.toDateString() });
				

			}
		}
		
	};
	componentDidMount() {
		void this.loadCalendarData();
	}
	async loadCalendarData(){
		await this.api.getSchedules().then(res => {
			if (res.status !== STATUS.OK)
				this.setState({ schedules: [] });
			else
				this.setState({ schedules: res.data });
		}).catch((error) => {
			console.log(error);
		});
	}
	addScheduleSelect = (id: number) => {
		
		this.setState({ scheduleAddForm: false, selectable:true, scheduleId: id });
		//this.calendarRef!.select("2024-06-01");
	};
	updateScheduleCalendar = (id: number) =>{
		//
		this.setState({ updateCalendarForm: false, selectable: false, scheduleId: id });
		alert("Updated Calendar");
	};
	addScheduleCalendar  = (id: number) => {
		const startTimeStamp = new Date(this.state.startDate).getTime(); // Create a Date object with a specific date
		const endTimeStamp = new Date(this.state.endDate).getTime(); // Create a Date object with a specific date
		const data: CalendarSchedule = {
			"calendarId": 1,
			"scheduleId": id,
			"start": startTimeStamp,
			"end": endTimeStamp,
			"id": 0
		};
		this.api.calendarAssignSchedule(data).then(res => {
			if (res.status === STATUS.OK) {
				this.setState({ scheduleAddConfirm: false, selectable: false });
				void this.loadCalendarData();
				alert("Schedule Added");
			}else{
				this.setState({ scheduleAddConfirm: false, selectable: false });
				alert("Schedule Add Fail");
			}
		}).catch((error) => {
			console.log(error);
			this.setState({ scheduleAddConfirm: false, selectable: false });
		});
	};
	dayCellClassNames = (arg: DayCellContentArg) => {
		// Get all events in the calendar view
		const eventsOnDate = arg.view.calendar.getEvents();

		// Check if the current date is between any event
		const isBetweenHolidayEvent = eventsOnDate.some(event => {
			const eventStartDate = new Date(event.startStr);
			const eventEndDate = new Date(event.endStr);
			return arg.date >= eventStartDate && arg.date <= eventEndDate && event.extendedProps.type === "vacation";
		});
		const isBetweenValcationEvent = eventsOnDate.some(event => {
			const eventStartDate = new Date(event.startStr);
			const eventEndDate = new Date(event.endStr);
			return arg.date >= eventStartDate && arg.date <= eventEndDate && event.extendedProps.type === "holiday";
		});
		const isBetweenScheduleEvent = eventsOnDate.some(event => {
			const eventStartDate = new Date(event.startStr);
			const eventEndDate = new Date(event.endStr);
			return arg.date >= eventStartDate && arg.date <= eventEndDate && event.extendedProps.type === "schedule";
		});
		const classNames = ["custom-day-cell"];

		if (isBetweenValcationEvent){
			classNames.push("vacation-event event");
		} else if (isBetweenHolidayEvent) {
			classNames.push("holiday-event event");
		} else if (isBetweenScheduleEvent){
			classNames.push("schedule-event event");
		}
		return classNames;
	};
	dayCellContent = (arg: DayCellContentArg) => {
		const eventsOnDate = arg.view.calendar.getEvents();
		
		const isBetweenHolidayEvent = eventsOnDate.find(event => {
			const eventStartDate = new Date(event.startStr);
			const eventEndDate = new Date(event.endStr);
			return (arg.date >= eventStartDate && arg.date <= eventEndDate && event.extendedProps.type === "holiday") ? event : null;
		});
		
		const isSameVacationStartEndDate = eventsOnDate.find(event => {
			const eventStartDate = new Date(event.startStr);
			
			const eventEndDate = new Date(event.endStr);
			return eventStartDate == eventEndDate && event.extendedProps.type === "vacation" ? event : null;
		});
		
		const isVacationStartDate = eventsOnDate.find(event => {
			const eventStartDate = new Date(event.startStr);
			eventStartDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			const currentDate = new Date(arg.date);
			currentDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			return eventStartDate.toISOString() == currentDate.toISOString() && event.extendedProps.type === "vacation" ? event : null;
		});
		
		const isVacationEndDate = eventsOnDate.find(event => {
			const eventEndDate = new Date(event.endStr);
			eventEndDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			const currentDate = new Date(arg.date);
			currentDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC
			if (event.extendedProps.type === "vacation"){
				//
			}
			
			return eventEndDate.toISOString() == currentDate.toISOString() && event.extendedProps.type === "vacation" ? event : null;
		});

		const isBetweenScheduleStartEvent = eventsOnDate.find(event => {
			const eventStartDate = new Date(event.startStr);
			eventStartDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			const currentDate = new Date(arg.date);
			currentDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			return currentDate.toUTCString() == eventStartDate.toUTCString() && event.extendedProps.type === "schedule" ? event : null;
		});
		const isBetweenScheduleEndEvent = eventsOnDate.find(event => {
			const eventEndDate = new Date(event.endStr);
			eventEndDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			const currentDate = new Date(arg.date);
			currentDate.setUTCHours(0, 0, 0, 0); // Set time part to 00:00:00.000 UTC

			return currentDate.toUTCString() == eventEndDate.toUTCString() && event.extendedProps.type === "schedule" ? event : null;
		});


		
		const isScheduleOnFirstDate = eventsOnDate.find(event => {

			const eventStartDate = new Date(event.startStr);
			const eventEndDate = new Date(event.endStr);
			return (arg.date >= eventStartDate && arg.date <= eventEndDate && event.extendedProps.type === "schedule" && arg.date.getDate() == 1) ? event : null;
		});

		let dateStr = "";
		if (isBetweenHolidayEvent != null){
			dateStr = dateStr + isBetweenHolidayEvent.title;
		}
		if (isSameVacationStartEndDate != null) {
			dateStr = dateStr + isSameVacationStartEndDate.title+"<br/><span>Vacation<span>";
		}
		if (isVacationStartDate != null) {
			dateStr = dateStr + isVacationStartDate.title + "<br/><span>Start of Vacation<span>";
		}
		if (isVacationEndDate != null) {
			dateStr = dateStr + isVacationEndDate.title + "<br/><span>End of Vacation<span>";

		}
		if (isBetweenScheduleStartEvent){
			dateStr = dateStr + isBetweenScheduleStartEvent.title + "<br/><span>Start<span>";

		}
		if (isBetweenScheduleEndEvent) {
			dateStr = dateStr + isBetweenScheduleEndEvent.title + "<br/><span>End<span>";

		}
		if (isScheduleOnFirstDate != null && dateStr == "") {
			dateStr = dateStr + isScheduleOnFirstDate.title + "";

		}
		if (dateStr == ""){
			return { html: `<div class="event-date">${arg.dayNumberText}</div>` };

		}else{
			return { html: `<div class="event-date">${arg.dayNumberText}</div><div class="event-title">${dateStr}</div>` };

		}
		//dateStr = "Start";

	};
	dayCellDidMount = (arg: DayCellMountArg) => {
		const cellElement = arg.el;
		// Add event listener for mouseenter event
		const hasEvent = cellElement.classList.contains("event");
		if (!hasEvent) {
			return;
		}
		cellElement.addEventListener("mouseenter", () => {
			// Logic to show tooltip when hovering over the day cell
			const tooltip = document.createElement("div");
			tooltip.className = "tooltip";
			tooltip.innerHTML = "Hours of Day: 8 Hrs<br/>Hours of Week: 40 hrs"; // Add your tooltip details here
			document.body.appendChild(tooltip);

			// Position the tooltip relative to the mouse pointer
			const rect = cellElement.getBoundingClientRect();
			tooltip.style.top = (rect.top - 25).toString() + "px";
			tooltip.style.left = rect.left.toString() + "px";
		});

		// Add event listener for mouseleave event to hide the tooltip
		cellElement.addEventListener("mouseleave", () => {
			// Logic to hide tooltip when mouse leaves the day cell
			const tooltip = document.querySelector(".tooltip");
			if (tooltip) {
				tooltip.parentNode!.removeChild(tooltip);
			}
		});
	};
	render() {
		const t = this.props.t;
		const yearlyHours = {
			work: 1864,
			holiday: 12 * 8,
			vacations: 120,
		};
		styles;

		return (
			<section className="pageContent" style={{ display: "flex", flexDirection: "column" }}>
				<div className="page-header">
					<h2>{t("calendarViewPage.title")}</h2>
					<ol className="breadcrumb">
						<li>{t("calendarListPage.title")}</li>
					</ol>
				</div>
				<div className={styles.calendarSummary}>
					<CalendarSummary summary={this.state.summary} yearlyHours={yearlyHours} />
					<CalendarDirection></CalendarDirection>
				</div>
				
				<div className={styles.calendarView}>
					<div style={{ flex: 8 }}>
						<FullCalendar
							ref={this.calendarRef}// Store a reference to the FullCalendar instance

							plugins={[dayGridPlugin, interactionPlugin]}
							timeZone="UTC"
							height="auto"
							initialView="dayGridMonth"
							displayEventTime={false}
							displayEventEnd={false}
							showNonCurrentDates={false}
							fixedWeekCount={false}
							firstDay={1}
							selectable={this.state.selectable}
							selectMirror={this.state.selectable}

							headerToolbar={{
								left: "title",
								// center: "myCustomButton",
								right: "prev,next",
							}}
							dayCellClassNames={this.dayCellClassNames}
							dayCellDidMount={this.dayCellDidMount}
							dayCellContent={this.dayCellContent}
							dateClick={this.handleDateClick}
							events={[
								
								{
									start: "2024-03-23T00:00:00",
									end: "2024-05-27T23:59:59",
									title: "Test Schedule",
									description: "Comienzo",
									display: "none",
									type: "schedule",
									allDay: false,
									textColor: "#000000",

								},
								{
									start: "2024-03-29T00:00:00",
									end: "2024-04-02T23:59:59",
									allDay: false,
									title: "Vacation 1",
									description: "Festivos",
									display: "none",
									type: "vacation",

								},
								{
									start: "2024-03-31T00:00:00",
									end: "2024-03-31T23:59:59",
									allDay: false,
									title: "Holiday in Vacation",
									description: "Festivos",
									display: "none",
									type: "holiday",

								},
								{
									start: "2024-04-06T00:00:00",
									end: "2024-04-06T23:59:59",
									title: "Horario Oficina",
									description: "Comienzo",
									allDay: false,
									display: "none",
									type: "holiday",

								},
								{
									start: "2024-04-07T00:00:00",
									end: "2024-04-07T23:59:59",
									title: "Horario Oficina 2",
									description: "Comienzo",
									display: "none",
									type: "holiday",
									allDay: false,

								}

							]}
							
						/>

					</div>
					<div className={styles.scheduleClass} style={{ flex: 2, display: "flex" }}>
						<Schedules schedules={this.state.schedules} />
					</div>
					
					<ScheduleAdd schedules={this.state.schedules} scheduleAddForm={this.state.scheduleAddForm} onDismiss={() => this.setState({ scheduleAddForm: false })} addScheduleSelect={this.addScheduleSelect}></ScheduleAdd>
					<ScheduleAddConfirm scheduleAddConfirm={this.state.scheduleAddConfirm} schedules={this.state.schedules} scheduleId={this.state.scheduleId} onDismiss={() => this.setState({ scheduleAddConfirm: false })} addScheduleCalendar={this.addScheduleCalendar} startDate={this.state.startDate} endDate={this.state.endDate}></ScheduleAddConfirm>
					<UpdateCalendar onDismiss={() => this.setState({ updateCalendarForm: false })} updateScheduleCalendar={this.updateScheduleCalendar} updateCalendarForm={this.state.updateCalendarForm} updateType={this.state.updateType}></UpdateCalendar>
				</div>
			</section>

		);
	}
}
interface CalendarListComponentProps extends WithTranslation {
}
type CalendarListComponentState = {
	summary: {
		work: number,
		holiday: number,
		vacations: number,
	};
	scheduleAddForm:boolean;
	schedules: ScheduleSummarized[];
	selectable: boolean,
	scheduleAddConfirm: boolean,
	scheduleId: number,
	startDate: string,
	endDate: string,
	updateCalendarForm: boolean,
	updateType: string,
};
function CalendarDirection() {
	return (
		<div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
			{Object.entries(calendarColors).map(([type, color]) => (
				<div key={type} style={{ marginRight: "10px", display: "flex" }}>
					<div style={{ width: "20px", height: "20px", backgroundColor: color }}></div>
					<span style={{ marginLeft: "5px" }}>{type}</span>
				</div>
			))}
		</div>
	);
}

function CalendarSummary(props: { summary: CalendarSummarized["assignedHours"]["current"]; yearlyHours: CalendarSummarized["yearlyHours"]; }) {
	return (
		<div style={{ display: "block" }}>
			<h1>Resumen del calendario</h1>
			<div>{props.summary.work}/{props.yearlyHours.work} Horas de trabajo</div>
			<div>{props.summary.holiday}/{props.yearlyHours.holiday} Horas de festivos</div>
			<div>{props.summary.vacations}/{props.yearlyHours.vacations} Horas de vacaciones</div>
		</div>
	);
}

function Schedules(props: { schedules: ScheduleSummarized[] }) {
	return (
		<>
			{
				props.schedules.map((schedule) => (
					<div key={schedule.id} className={styles.schedule}>
						<div className="card">
							<div className="head">
								{schedule.name}
							</div>
							<div className="body text-center">
								{schedule.weeklyWorkHours} Horas
							</div>
						</div>
					</div>
				))
			}
		</>
	);

}

export default withTranslation()(CalendarViewPage);
const calendarColors = {
	vacation: "#4286f4", // Blue
	schedule: "#2eff4e",    // Yellow
	holiday: "#ff7043"   // Orange
};