import { Component, ChangeEvent, createRef } from "react";
import { ScheduleSummarized, Shift } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class UpdateCalendar extends Component<UpdateCalendarProps, UpdateCalendarState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: UpdateCalendarProps) {
        super(props);
        this.state = { id: 0 };
    }


    componentDidUpdate(prevProps: UpdateCalendarProps) {
        if (this.props.updateCalendarForm !== prevProps.updateCalendarForm) this.updateShown();
    }
    updateShown() {
        if (this.props.updateCalendarForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.updateCalendarForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        if (this.props.updateCalendarForm == false) { return null; }
        return (
            <>
                <h1 className="head">Add Schdule</h1>
                <div className="body" style={{ flex: 1 }}>
                   
                    {this.props.updateType == "schedulemid" ? 
                        ( 
                            <>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Assign holiday</div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Assign fixed vacations </div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Change schedule start </div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Change schedule end</div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Remove schedule assignment  </div>
                                </div>
                            </>
                        ) : this.props.updateType == "schedulestart" ?
                        (
                            <>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Modify Schedule Start  </div>
                                </div>
                                
                            </>
                        ) : this.props.updateType == "scheduleend" ?
                            (
                                <>
                                    <div className="d-flex">
                                        <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Modify Schedule End  </div>
                                    </div>

                                </>
                        ) : this.props.updateType == "holiday" ? 
                        (
                            <>
                                <div className="d-flex">
                                        <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Modify Holiday  </div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Remove Holiday  </div>
                                </div>
                            </>
                        ) : this.props.updateType == "vacationmid" ?
                            (
                                <>
                                    <div className="d-flex">
                                        <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Add Holiday  </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Remove fixed vacations  </div>
                                    </div>
                                </>
                                ) : this.props.updateType == "vacationstart" ?
                                (
                                    <>
                                        <div className="d-flex">
                                                    <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Change fixed vacation start day  </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Remove fixed vacations  </div>
                                        </div>
                                    </>
                                ) : this.props.updateType == "vacationend" ?
                                    (
                                        <>
                                            <div className="d-flex">
                                                <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Change fixed vacation end day  </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="w-100"><input type="radio" className="form-control" name="schedule" value={this.props.updateType} /> Remove fixed vacations  </div>
                                            </div>
                                        </>
                                    ) : null
                    }
                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.updateScheduleCalendar(this.state.id)}>Update</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>

                </div>
            </>
        );
    }

}
interface UpdateCalendarProps {
    updateCalendarForm: boolean;
    onDismiss: () => void;
    updateScheduleCalendar: (id: number) => void;
    updateType: string;

}
interface UpdateCalendarState {
    id: number
}
