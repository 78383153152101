
import styles from "../index.module.scss";
import { useTranslation } from "react-i18next";
import { Message } from "../../../Api/ApiDefinition";


interface EmployeeMessagesProps {
    messages: Message[];
    onClickArchive: (msg: Message) => void;
}
export function EmployeeMessagesTable(props: EmployeeMessagesProps) {

    const { t } = useTranslation("translation", { keyPrefix: "messagesListPage.table" });
    return (

        <table className="commonTable">
            <thead>
                <tr>
                    <th>{t("header.message")}</th>
                    <th>{t("header.time")}</th>
                </tr>
            </thead>
            <tbody>
                {props.messages.map((msg) => MessageRow(msg))}
            </tbody>
        </table>

    );
}

function MessageRow(msg: Message) {
    return (
        <tr key={msg.id} id={`row-${msg.id}`}>
            <td  className="cursorPointer"><span className={styles.subject}>{msg.subject}</span> - {msg.body}</td>
            <td className="text-right cursorPointer">{formatTs(msg.timestamp)}</td>
        </tr>
    );
}

function formatTs(ts: number) {
    const now = new Date();
    const date = new Date(ts * 1000);
    const sameDate = now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear();
    let formatter: Intl.DateTimeFormat;
    if (sameDate)
        formatter = new Intl.DateTimeFormat("en", { hour: "2-digit", minute: "2-digit", hour12: true });
    else if (now.getFullYear() === date.getFullYear())
        formatter = new Intl.DateTimeFormat("es", { month: "short", day: "numeric" });
    else
        formatter = new Intl.DateTimeFormat("es", { year: "numeric", month: "short", day: "numeric" });

    return formatter.format(date);
}