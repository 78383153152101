// import styles from './index.module.scss';
import { WithTranslation, withTranslation } from "react-i18next";
import { CalendarListComponent } from "./CalendarListComponent";
import { ScheduleListComponent } from "./ScheduleListComponent";
import { CalendarSummarized, STATUS, ScheduleSummarized, getApiInstance } from "../../Api/ApiDefinition";
import { Component } from "react";
import styles from "./index.module.scss";

class CalendarListPage extends Component<CalendarListPageProps, CalendarListPageState> {
	api = getApiInstance();

	constructor(props: CalendarListPageProps) {
		super(props);

		this.state = { calendars: [], schedules: [], loading: true };
	}

	componentDidMount() {
		void this.queryCalendars();
		void this.querySchedules();
	}

	async queryCalendars() {
		const calendars = await this.api.getCalendars();

		if (calendars.status !== STATUS.OK)
			this.setState({ calendars: [], loading: false });
		else
			this.setState({ calendars: calendars.data, loading: false });
	}
	async querySchedules() {
		const schedules = await this.api.getSchedules();

		if (schedules.status !== STATUS.OK)
			this.setState({ schedules: [], loading: false });
		else
			this.setState({ schedules: schedules.data , loading: false});
	}

	render() {
		const t = this.props.t;

		styles;	//Load it!

		return (
			<section className="pageContent">
				<div className="page-header">
					<h2>{t("calendarListPage.title")}</h2>
					<ol className="breadcrumb">
						<li className="active">{t("calendarListPage.title")}</li>
					</ol>
				</div>

				
				<CalendarListComponent loading={this.state.loading} calendars={this.state.calendars} />
				
				<ScheduleListComponent loading={this.state.loading} schedules={this.state.schedules} />
			</section>
		);
	}
}

interface CalendarListPageProps extends WithTranslation {
}
type CalendarListPageState = {
	calendars: CalendarSummarized[];
	schedules: ScheduleSummarized[];
	loading: boolean;
};

export default withTranslation()(CalendarListPage);