
import { Message } from "../../Api/ApiDefinition";
import { MessagesTable } from "./MessageListComponent";
import { MessageModal } from "../../Components/CommonModals/Message/MessageModal";
import { WithTranslation, withTranslation } from "react-i18next";
import { STATUS, getApiInstance } from "../../Api/ApiDefinition";
import { Component } from "react";
import styles from "./index.module.scss";


class MessagesListPage extends Component<MessagesPageComponentProps, MessagesPageComponentState> {
	api = getApiInstance();

	constructor(props: MessagesPageComponentProps) {
		super(props);

		this.state = {
			messages: [],
			messageShownModal: null,
			showArchived: false,
			searchValue: "",
			pageNumber: 1,
			perPage: 10,
			currentItems: [],
			showLoading: true,


		};
	}

	componentDidMount() {
		void this.queryMessages({ keyword: "" });
	}

	async queryMessages({ keyword = "", pageNumber = 1}) {
		this.setState({ showLoading: true });
		try{
			const items = await this.api.getMessages(this.state.showArchived, keyword, this.state.pageNumber, this.state.perPage);
			
			if (items.status !== STATUS.OK)
				this.setState({ messages: [], showLoading: false });
			
			else
			this.setState({
				messages: items.data,
				pageNumber: 1, // Reset to the first page when new items are fetched
				currentItems: items.data.slice(0, this.state.perPage),
				showLoading: false
			});
		} catch(error) {
			console.error("Error archiving message:", error);
			// Optionally, update the UI to reflect the error state
		}
		
	}
	toggleArchiived(){
		this.setState({ showArchived: !this.state.showArchived },  () => {
			void this.queryMessages({keyword: ""});
		});

	}
	handlePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newPerPage = parseInt(event.target.value, 10);
		this.setState({ perPage: newPerPage, pageNumber: 1 }, () => {
			void this.queryMessages({ keyword: this.state.searchValue.length >= 3 ? this.state.searchValue  : ""});
		}); 


	};
	handleSeach = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		this.setState({ searchValue: value }, () => {
			if (value.length >= 3) {
				void this.queryMessages({keyword: value});
			} else if (value.length == 0){
				void this.queryMessages({ keyword: "" });
			}
		});
	};
	async setMessageArchived(msg: Message) {
		try {
			const items = await this.api.setMessageArchived(msg.id, !msg.archived);
			if (items.status !== STATUS.OK){
				throw new Error("Failed to archive message");
			}else{
				const rowElement = document.getElementById(`row-${msg.id}`);
				if (rowElement) {
					rowElement.classList.add("row-exit-active");
				}
				void this.queryMessages({ keyword: this.state.searchValue, pageNumber: this.state.pageNumber});
				
			}
		} catch (error) {
			console.error("Error archiving message:", error);
			// Optionally, update the UI to reflect the error state
		}

	}
	handleNextPage = () => {
		const { pageNumber, perPage, messages } = this.state;
		const nextPage = pageNumber + 1;
		const startIdx = (nextPage - 1) * perPage;
		const endIdx = startIdx + perPage;
		const nextPageItems = messages.slice(startIdx, endIdx);

		if (nextPageItems.length > 0) {
			this.setState({
				pageNumber: nextPage,
				currentItems: nextPageItems,
			});
		}
	};
	handlePrevPage = () => {
		const { pageNumber, perPage, messages } = this.state;
		const prevPage = Math.max(1, pageNumber - 1);
		const startIdx = (prevPage - 1) * perPage;
		const endIdx = startIdx + perPage;
		const prevPageItems = messages.slice(startIdx, endIdx);

		this.setState({
			pageNumber: prevPage,
			currentItems: prevPageItems,
		});
	};
	handlePageClick(pageNumber: number) {
		// Handle page click logic here
		const startIdx = (pageNumber - 1) * this.state.perPage;
		const endIdx = startIdx + this.state.perPage;
		const newItems = this.state.messages.slice(startIdx, endIdx);
		this.setState({
			pageNumber,
			currentItems: newItems,
		});
	}
	renderPaginationButtons = () => {
		const buttons = [];
		const maxPage = Math.ceil(this.state.messages.length / this.state.perPage);

		for (let i = 1; i <= maxPage; i++) {
			buttons.push(
				<button title={`Page ${i}`} key={i} className="pageNumber" onClick={() => this.handlePageClick(i)} disabled={i === this.state.pageNumber}>
					{i}
				</button>
			);
		}
		return buttons;
	};
	render() {
		const t = this.props.t;
		const maxPage = Math.ceil(this.state.messages.length / this.state.perPage);

		styles;	//Load it!

		return (
			<section className="pageContent">
				<div className="page-header">
					<h2>{t("messagesListPage.title")}</h2>
					<ol className="breadcrumb">
						<li className="active">{t("messagesListPage.title")}</li>
					</ol>
				</div>
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							{t("messagesListPage.new")}&nbsp;&nbsp;<label className="switch">
								<input onClick={() => void this.toggleArchiived()} type="checkbox"></input>
								<span className="slider round"></span>
							</label>&nbsp;&nbsp;{t("messagesListPage.archived")} 
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 10 }}>
								<input
									type="search"
									placeholder="Search..."
									value={this.state.searchValue}
									onChange={this.handleSeach}
								/>
							</div>
							<div>
								<select name="page" id="page" value={this.state.perPage} onChange={this.handlePageSize}>
									<option  value={5}>5</option>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={50}>50</option>
								</select>
							</div>
							
						</div>
					</div>
					
					<MessagesTable loading={this.state.showLoading} messages={this.state.currentItems} onClickRow={(msg) => this.setState({ messageShownModal: msg })} onClickArchive={(msg) => void this.setMessageArchived(msg)} />
					{
						this.state.showLoading ? null :
					
					(	
						<div className="page">
							<div style={{ marginRight: 10 }}>{this.state.showLoading ? null : ("Total  "+ this.state.messages.length.toString() + " Results") }</div>

							<div className="pagination">
									<button className="btn btn-primary" title="Prevoius Page" onClick={this.handlePrevPage} disabled={this.state.pageNumber === 1}>
									{t("messagesListPage.previousPage")}
								</button>
									{this.renderPaginationButtons()}
								<button className="btn btn-primary"  title="Next Page" onClick={this.handleNextPage} disabled={this.state.pageNumber === maxPage}>{t("messagesListPage.nextPage")}</button>
							</div>
						</div>
					)}
					
				</div>
				<MessageModal shownMessage={MessagesListPage.getAlert(this.state.messageShownModal)} onDismiss={() => this.setState({ messageShownModal: null })} />
			</section>
		);
	}

	

	static getAlert(message: Message | null) {
		if (message == null) { return null; }

		return {
			title: message.subject,
			message: message.body
		};
	}
}

interface MessagesPageComponentProps extends WithTranslation {
}
type MessagesPageComponentState = {
	messages: Message[];
	messageShownModal: Message | null;
	showArchived : boolean;
	searchValue: string;
	pageNumber: number;
	perPage: number;
	currentItems: Message[];
	showLoading: boolean;

};

export default withTranslation()(MessagesListPage);
