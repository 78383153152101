import { Component } from "react";
import Progress from "../../Components/Progress/Progress";
import { CalendarBasicInfo, CalendarSummarized, getApiInstance } from "../../Api/ApiDefinition";
import { mdiCalendarRange, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { CalendarEditForm } from "./components/CalendarEdit";
import { CalendarAddForm } from "./components/CalendarAdd";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export class CalendarListComponent extends Component<CalendarListComponentProps, CalendarListComponentState> {
	api = getApiInstance();
	constructor(props: CalendarListComponentProps) {
		super(props);
		this.state = {
			showCalendarEditForm: false,
			showCalendarAddForm: false,
			calendar: {} as CalendarSummarized,
			showNextYear: false
		};
	}

	formatDate(timestamp: number | null) {
		if (timestamp === null) return "N/A";

		const date = new Date(timestamp * 1000);
		return date.toLocaleDateString();
	}
	changeYearlyHours = () => {
		console.log("dd");
		this.setState({ showNextYear: !this.state.showNextYear });
	};
	row(calendar: CalendarSummarized) {
		return (
			<tr key={calendar.id}>
				<td>{calendar.id}</td>
				<td>{calendar.name}</td>
				<td>{this.formatDate(calendar.firstDate)} - {this.formatDate(calendar.lastDate)}</td>

				<td><div className={styles.yearlytotal}><span>Work: {calendar.yearlyHours.work} hours</span>
					<span>Holiday: {calendar.yearlyHours.holiday} hours</span>
					<span>Vacations: {calendar.yearlyHours.vacations} hours</span></div></td>

				<td>
					<div onClick={this.changeYearlyHours}
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "5px",
							margin: "5px 0",
							height: "calc(100% - 10px)",
						}}
					>
						<Progress
							value={this.state.showNextYear ? calendar.assignedHours?.next.work:calendar.assignedHours?.current.work}
							max={calendar.yearlyHours?.work}
						/>
						<Progress
							value={this.state.showNextYear ? calendar.assignedHours?.next.holiday : calendar.assignedHours?.current.holiday}
							max={calendar.yearlyHours?.holiday}
						/>
						<Progress
							value={this.state.showNextYear ? calendar.assignedHours?.next.vacations : calendar.assignedHours?.current.vacations}
							max={calendar.yearlyHours?.vacations}
						/>
					</div>
				</td>
				<td className="text-center">{calendar.assignedEmployees}</td>
				<td>
					<button title="Edit" onClick={() => this.openEditForm(calendar)} className="toggleButton mr1">
						<Icon
							size={1}
							path={mdiPencil}
						/>
					</button>
					<Link to={"/calendarView?id=" + calendar.id.toString()}>
						<button title="View Calendar" className="toggleButton">
							<Icon
								size={1}
								path={mdiCalendarRange}
							/>
						</button>
					</Link>
					

				</td>
			</tr>
		);
	}
	openEditForm(calendar: CalendarBasicInfo) {
		this.setState({ showCalendarEditForm: true, calendar: calendar });
	}
	updateCalendar = (calendar: CalendarBasicInfo, id: number) => {

		this.api.postCalendar(id, calendar).then((data) => {
			console.log(data);
			this.setState({ showCalendarEditForm: false });
		}).catch((error) => {
			console.log(error);
			this.setState({ showCalendarEditForm: false });
		});

		//console.log("test");

	};
	addCalendar = (calendar: CalendarBasicInfo, id: number) => {
		this.api.postCalendar(id, calendar).then((data) => {
			console.log(data);
			this.setState({ showCalendarAddForm: false });
		}).catch((error) => {
			console.log(error);
			this.setState({ showCalendarAddForm: false });
		});

		//console.log("test");

	};
	render() {
		styles;	//Load it!

		return (
			<div>
				<div className="table-header"><h3>Calendars</h3><button title="Add Calendar" className="btn btn-primary" onClick={() => this.setState({ showCalendarAddForm: true }) }>Add Calendar</button></div>
				<div className="overflowx">
					<table className="commonTable mt1">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Spanning</th>
							<th>Yearly Totals</th>
							<th>Completeness - 2024</th>
							<th>Employees</th>
							<th>Actions</th>
						</tr>
					</thead>
					{ this.props.loading ? (
						<tbody>
							<tr className="skeleton"><td colSpan={9} className="text-center">Loading...</td></tr>
						</tbody>
					) : (
						<tbody>
							{this.props.calendars.map((cal) => this.row(cal))}
						</tbody>
					)}
				
					</table>
				</div>
				<CalendarEditForm showCalendarEditForm={this.state.showCalendarEditForm} calendar={this.state.calendar} onDismiss={() => this.setState({ showCalendarEditForm: false })} updateCalendar={this.updateCalendar} ></CalendarEditForm>
				<CalendarAddForm showCalendarAddForm={this.state.showCalendarAddForm} onDismiss={() => this.setState({ showCalendarAddForm: false })} addCalendar={this.addCalendar} ></CalendarAddForm>

			</div>
		);
	}
}

interface CalendarListComponentProps {
	calendars: CalendarSummarized[];
	showAssignedHours?: (calendar: CalendarSummarized | null) => void;
	loading: boolean;
	
}
interface CalendarListComponentState {
	showCalendarEditForm:boolean;
	showCalendarAddForm:boolean;
	calendar: CalendarBasicInfo;
	showNextYear: boolean;
}
