import React, { Component, createRef, useEffect, useRef, useState } from "react";
import {  EmployeeBasicInfo, STATUS, Tag, getApiInstance } from "../../../Api/ApiDefinition";
import style from "./AssignTags.module.css";
interface AssignTagModalProps {
	shownMessage: boolean;
	onDismiss: () => void;
	assignTags: (addTags: number[], removeTags: number[]) => void;
	tags: Tag[];
	selectedEmployee: EmployeeBasicInfo[];
}


export class AssignTagModal extends Component<AssignTagModalProps, AssignTagState > {
	dialogRef = createRef<HTMLDialogElement>();
	api = getApiInstance();

	constructor(props: AssignTagModalProps) {
		super(props);
		this.state = {
			selectedTags: [],
			removedTags: [],
			newTag: "",
			isVisible: false
		};
	}

	componentDidUpdate(prevProps: AssignTagModalProps) {
		if (this.props.shownMessage !== prevProps.shownMessage) this.updateShown();
	}
	updateShown() {
		if (this.props.shownMessage != false) {
			this.dialogRef.current?.showModal();

		} else if (this.props.shownMessage == false) {
			this.dialogRef.current?.close();
			this.setState({ selectedTags: [], removedTags: [] });
		}
	}
	assignTags(){
		this.props.assignTags(this.state.selectedTags, this.state.removedTags);
		this.dialogRef.current?.close();
	}
	fnSelectedTags = (tagId: number, type: string) => {
		console.log(type);
		if (type == "remove") {
			
			this.setState({ removedTags: [...this.state.removedTags, tagId] });
			if (this.state.selectedTags.includes(tagId)){
				this.setState({ selectedTags: this.state.selectedTags.filter(tag => tag != tagId) });
			}

		} else if(type == "add") {
			
			this.setState({ selectedTags: [...this.state.selectedTags, tagId] });
		}
	};
	addNewTag = async () =>{
		console.log("asda");
		await this.api.putTag({ text: this.state.newTag }).then((res) => {
			if(res.status == STATUS.OK){
				this.setState({ newTag: "", isVisible: !this.state.isVisible });
				this.props.tags.push(res.data);
				//alert("Tag created successfully");
			}
			

		}).catch(() => {
			alert("Failed to create tag");
		});
	};
	DialogBody() {
		if (this.props.shownMessage == false) { return null; }

		return (
			<>
				<h1 className="head">
					Select Tags
					
				</h1>
				<div className="body">
					<div className="modalsubline"><p>Select tags from the available list</p>
					{!this.state.isVisible && <button className="btn btn-primary" onClick={() => this.setState({ isVisible: !this.state.isVisible })}>Add New Tag</button> }
					{ this.state.isVisible &&
						<div>
							<input type="text" className="mr1" name="newTag" onChange={(e) => this.setState({ newTag: e.target.value })} />
							<button className="btn btn-primary mr1" onClick={() => void this.addNewTag()}>Add</button>
							<button className="btn" onClick={() => this.setState({ isVisible: !this.state.isVisible })}>Cancel</button>
						</div>
					}
					</div>
					<div>
						
						<div>
							{
								this.props.tags.map(tag => <TagMap key={tag.id} fnSelectedTags={this.fnSelectedTags} tag={tag} selectedEmployees={this.props.selectedEmployee}>
									{tag.text}
								</TagMap>)
							}
							
						</div>
					</div>
				</div>
				<div className="footer">
					<button className="btn btn-primary mr1" onClick={() => this.assignTags()}>Add Tags ({this.state.selectedTags.length})</button>
					<button className="btn" onClick={() => this.dialogRef.current?.close()}>Cancel</button>
				</div>
			</>
		);
	}

	render() {
		if (this.dialogRef.current != null)
			this.dialogRef.current.onclose = this.props.onDismiss;

		return (
			<dialog className={style.assignTags} ref={this.dialogRef}>
				{this.DialogBody()}
			</dialog>
		);
	}
}
interface AssignTagState { selectedTags: number[]; removedTags: number[]; newTag: string; isVisible: boolean;  }

interface TagMapProps {
	children: string;
	tag: Tag;
	fnSelectedTags: (id: number, type: string) => void;
	selectedEmployees: EmployeeBasicInfo[];
}

function TagMap(props: TagMapProps): React.ReactElement {
	const [isChecked, setIsChecked] = useState<boolean | null>(false);
	const [prevChecked, setPrevChecked] = useState<boolean | null>(false);
	const checkboxRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setPrevChecked(isChecked);
	}, [isChecked]);

	useEffect(() => {
		const isTagPresentInAllSelectedEmployees = props.selectedEmployees.every(employee =>
			employee.tags.some(tag => tag.id === props.tag.id)
		);

		const isTagPresentInAtLeastOneSelectedEmployee = props.selectedEmployees.some(employee =>
			employee.tags.some(tag => tag.id === props.tag.id)
		);

		setIsChecked(isTagPresentInAllSelectedEmployees ?
			true :
			(isTagPresentInAtLeastOneSelectedEmployee ? null : false)
		);

		if (checkboxRef.current) {
			checkboxRef.current.indeterminate = isTagPresentInAtLeastOneSelectedEmployee && !isTagPresentInAllSelectedEmployees;
		}
	}, [props.selectedEmployees, props.tag.id]);

	const handleDivClick = () => {
		if (isChecked === null) {
			setIsChecked(false); // When in intermediate state, clicking should uncheck it
		} else {
			setIsChecked(!isChecked); // Toggle the checkbox state
		}
		
	};
	useEffect(() => {
		if (prevChecked !== null && isChecked !== prevChecked) {
			if (isChecked) {
				props.fnSelectedTags(props.tag.id, "add"); // Call the function to handle selected tags
			} else {
				// Tag removed
				props.fnSelectedTags(props.tag.id, "remove"); // Call the function to handle selected tags
			}
		}
	}, [isChecked, prevChecked, props.tag]);

	return (
		<div className={style.calendarMap} onClick={handleDivClick}>
			<input
				ref={checkboxRef}
				type="checkbox"
				name="tags"
				value={props.tag.id}
				checked={isChecked === true} // Check if isChecked is true
			/>
			{props.tag.text}
		</div>
	);
}