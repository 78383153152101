import Icon from "@mdi/react";
import styles from "./WeekView.module.scss";
import React from "react";
import { mdiCheckAll, mdiChevronDown, mdiDownload, mdiMessageText, mdiMessageTextOutline, mdiPencil } from "@mdi/js";
import { PlainWeek } from "../../Libs/PlainWeek";
import { Shift, getApiInstance } from "../../Api/ApiDefinition";

const NEARBY_WEEKS = 2;

export class WeekView extends React.Component<Props, State>{
	api = getApiInstance();
	constructor(props: Props) {
		super(props);

		const start = Temporal.Now.plainDateISO();
		this.state = {
			plainWeek: PlainWeek.from(start),
			loadedData: {},
			loading: true
		};
	}

	get currentView(): PlainWeek {
		return this.state.plainWeek;
	}
	set currentView(value: PlainWeek | number | "now") {
		let plainWeek: PlainWeek;

		if (value instanceof PlainWeek) plainWeek = value;
		else if (value == "now") plainWeek = plainWeek = PlainWeek.from("now");
		else plainWeek = this.currentView.add(value);

		this.loadNearbyData(plainWeek);
		this.setState({ plainWeek });
	}
	getLoadedData(week: PlainWeek) {
		return this.state.loadedData[week.toISOString()];
	}


	loadNearbyData(isoWeek: PlainWeek) {
		const weeks = new Set<PlainWeek>();
		if (this.getLoadedData(isoWeek) == null)
			weeks.add(isoWeek);

		for (let i = 1; i <= NEARBY_WEEKS; i++) {
			if (this.getLoadedData(isoWeek.add(i)) == null)
				weeks.add(isoWeek.add(i));

			if (this.getLoadedData(isoWeek.subtract(i)) == null)
				weeks.add(isoWeek.subtract(i));
		}

		const valuesStr = [...weeks.values()].map((week) => week.toISOString());

		void this.props.attendanceAccessor(valuesStr, []).then((data) => {
			data = { ...this.state.loadedData, ...data };
			this.setState({ loadedData: data });
		});
	}

	shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
		if (this.state.plainWeek != nextState.plainWeek) return true;
		const lastData = this.state.loadedData[this.state.plainWeek.toISOString()];
		const nextData = nextState.loadedData[nextState.plainWeek.toISOString()];
		if (lastData != nextData) return true;	//TODO This is not really checking if the value is the same, but if the reference is the same
		return false;
	}

	componentDidMount() {
		this.loadNearbyData(this.state.plainWeek);
		this.setState({ loading: false });
	}
	Export() {
		console.log("Export");
		this.api.exportTimeSheet(this.state.plainWeek.toISOString()).then((data) => {
			alert("Data Exported");
		}).catch(console.error);
	}
	render() {
		return (
			<div className={styles.schedulesPageContainer} role="main" aria-label="Schedule">
				<div className={styles.headerNavigator} role="row" aria-label="Schedule Navigator">
					<button onClick={() => this.currentView = "now"}>
						Hoy
					</button>
					<button style={{ aspectRatio: 1, fontSize: "125%" }} onClick={() => this.currentView = -1}>
						&lt;
					</button>
					<button style={{ aspectRatio: 1, fontSize: "125%" }} onClick={() => this.currentView = +1}>
						&gt;
					</button>
					<button>
						<span>{this.currentView.rangeString()}</span>
						<Icon path={mdiChevronDown} size={1} />
					</button>

					<div style={{ flex: 1 }}></div>

					<button>
						<span>Grupo Mostrado</span>
						<Icon path={mdiChevronDown} size={1} />
					</button>
					
					<button onClick={() => this.Export()}>
						<span>Exportar</span>
						<Icon path={mdiDownload} size={1} />
					</button>
				</div>
				<div className={styles.calendar}>
					<div className={styles.headerDays} role="row" aria-label="Schedule dates header row">
						<div className={styles.totalSemana}></div>

						{this.currentView.days.map(DayHeaderView)}
					</div>
					{this.state.loading ? <div className={"skeleton " + styles.skeletonWeekView}>Loading</div> : this.MemberElements}

				</div>
			</div>
		);
	}

	get MemberElements() {
		const week = this.currentView.toISOString();

		if (this.state.loadedData[week] == undefined)
			return this.currentView.days.map(() => "");
		// throw new Error("Data not loaded");

		const weekData = this.state.loadedData[week];

		return weekData.map((member) => MemberRow(member, this.state.plainWeek, this.props.onClickAttendanceRecord));
	}

}
interface State {
	plainWeek: PlainWeek;

	/** Format: ISO8601("2022-W01") */
	loadedData: Record<string, MemberAttendance[]>;
	loading: boolean;
}
interface Props {
	attendanceAccessor: (weeks: string[], employeeTags: unknown[]) => Promise<Record<string, MemberAttendance[]>>;
	onClickAttendanceRecord: (record: Shift, member: number) => void;
}


const DayHeaderView = (date: Temporal.PlainDate) => {
	console.log(date);
	const formatterDate = new Intl.DateTimeFormat("en", { day: "numeric" });
	const formatterDay = new Intl.DateTimeFormat("es", { weekday: "short" });
	const formatterMonth = new Intl.DateTimeFormat("en", { month: "short" });


	return (
		<div className={styles.dayHeader} role="columnheader" aria-label={date.toLocaleString()} key={date.toString()} >
			{formatterMonth.format(date)} - {formatterDate.format(date)}
		</div>
	);
};

const MemberRow = (member: MemberAttendance, daysShown: PlainWeek, onClickRecord: (record: Shift, member: number) => void) => {
	const dayCells = daysShown.days.map((date) => DayCell(member.records, date, onClickRecord, member.id));

	return (
		<div className={styles.memberRow} role="row" key={member.id}>
			<div className={styles.peopleColumnCell} role="rowheader" >
				<div role="presentation" className={styles.employeeIcon}>
					<img src={member.imageUrl} alt=""></img>
				</div>
				<div className={styles.employeeData}>
					<div className={styles.name}>{member.name}</div>
					<div className={styles.hoursShown}>
						{member.records.length} shifts
					</div>
				</div>
			</div>
			{dayCells}
		</div>
	);
};

const DayCell = (records: Shift[], date: Temporal.PlainDate, onClickRecord: (record: Shift, member: number) => void, member: number) => {
	const zone = { calendar: date.getCalendar(), timeZone: Temporal.Now.timeZoneId() };
	const shownRecord = (fichaje: Shift) => {
		const start = Temporal.Instant.
			fromEpochSeconds(fichaje.start)
			.toZonedDateTime(zone);

		const end = Temporal.Instant
			.fromEpochSeconds(fichaje.end != null ? fichaje.end : fichaje.start)
			.toZonedDateTime(zone);

		return start.toPlainDate().equals(date) || end.toPlainDate().equals(date);
	};

	const filteredRecords = records.filter(shownRecord);
	const htmlRecords = filteredRecords.map((record) => Record(record, onClickRecord));

	return (
		<div className={styles.dayCell} onClick={() => onClickRecord(filteredRecords[0], member)} >
			{htmlRecords}
		</div>
	);
};

const Record = (record: Shift, onClickRecord: (record: Shift, member: number) => void) => {
	// const { t } = useTranslation();
	const formatter = new Intl.DateTimeFormat("en", { timeStyle: "short", hour12: true });	// "1:31 PM"
	const formatTS = (ts: number) => formatter.format(new Date(ts * 1000));
	const formatTime = (seconds: number) => {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		// const seconds2 = seconds % 60;

		return `${hours} horas y ${minutes} minutos`;
	};
	let durationSecs  = 0;
	if (record.end != null){
		durationSecs = record.end - record.start;
	}
	console.log(record);
	
	return (
		<div className={`${styles.event} ${(record.id % 15 === 0 || record.id % 17 === 0) ? styles.vacation : ""} ${record.id % 6 === 0 ? styles.holiday : ""} ${record.isDeleted ? styles.deletedEvent : ""}`} key={record.id}>
			<div className={styles.eventIcons}>{record.notes != "" ? <Icon path={mdiMessageTextOutline} size={0.7} /> : ""} {record.startEdited != null ? <Icon path={mdiPencil} size={0.7} /> : ""} {record.confirmedEmployer == true ? <Icon path={mdiCheckAll} size={0.7} /> : ""}</div>
			<div>{formatTS(record.start)} - {formatTS(record.end != null ? record.end : record.start)}</div>
			<div>({formatTime(durationSecs)})</div>
		</div>
	);
};

interface MemberAttendance {
	id: number,
	name: string,
	imageUrl: string,
	tags: string[],
	records: Shift[] //Shift[],

}
