import styles from "../index.module.scss";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

const EmployeeLoadingComponent: React.FC = () => {
	return (
		<section className={styles.Profile}>
			<div style={{ flex: 8 }} className={styles.subSection}>
				<div style={{ display: "flex", gap: "20px" }}>
					<div className="card" style={{ flex: 1 }}>
						<div className={styles.loading + " " + styles.height250}>
							Loading
						</div>
					</div>
				</div>
				<div style={{ display: "flex", gap: "20px" }}>
					<div className="card loading" style={{ flex: 1 }}>
						<div className="head">
							Eventos Cercanos
						</div>
						<div className={styles.loading}>
							Loading
						</div>
						
					</div>
				</div>
				<div style={{ display: "flex", gap: "20px" }}>
					<div className="card loading" style={{ flex: 1 }}>
						<div className="head">
							Comunicaciones Recientes
						</div>
						<div className={styles.loading}>
							Loading
						</div>
					</div>
				</div>
				


			</div>

			<div style={{ flex: 11 }} className={styles.subSection}>
				<div style={{ display: "flex", gap: "20px" }}>
					<div className="card" style={{ flex: 1 }}>
						<div className={styles.loading}>
							Loading
						</div>
						
					</div>
				</div>
				<div className="card">
					<div className="body">
						<div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
							
						</div>
						<FullCalendar
							plugins={[dayGridPlugin, interactionPlugin]}
							height="auto"
							initialView="dayGridMonth"
							displayEventTime={false}
							displayEventEnd={false}
							showNonCurrentDates={false}
							fixedWeekCount={false}
							firstDay={1}
							headerToolbar={{
								left: "title",
								// center: "myCustomButton",
								right: "prev,next",
							}}
						
						/>
						
					</div>
				</div>
				
			</div>

		</section>
	);
};

export default EmployeeLoadingComponent;
