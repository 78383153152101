
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Message } from "../../Api/ApiDefinition";
import Icon from "@mdi/react";
import { mdiArchive, mdiArchiveOff } from "@mdi/js";
import { Link } from "react-router-dom";

interface TableProps {
	messages: Message[];
	onClickRow: (msg: Message) => void;
	onClickArchive: (msg: Message) => void;
	loading: boolean;
}
export function MessagesTable(props: TableProps) {
	const { t } = useTranslation("translation", { keyPrefix: "messagesListPage.table" });

	const renderMessageRows = () => {
		if (props.messages.length > 0) {
			return props.messages.map((msg) => MessageRow(msg, props.onClickRow, props.onClickArchive));
		} else {
			return <tr><td colSpan={4} className="text-center">No messages</td></tr>;
		}
	};
	return (
		
			<table className="commonTable">
				<thead>
					<tr>
						<th >{t("header.employee")}</th>
						<th>{t("header.message")}</th>
						<th className="text-right">{t("header.time")}</th>
						<th className="text-center">{t("header.archive")}</th>
					</tr>
				</thead>
				{
				props.loading  ? (
					<tbody>
						
						<tr className="skeleton"><td colSpan={4} className="text-center">Loading...</td></tr>
						
					</tbody>
				) 
				: <tbody>
						{renderMessageRows()}
				</tbody>
				}
				
			</table>
		
	);
	
}

function MessageRow(msg: Message, onClickRow: (msg: Message) => void, onClickArchive: (msg: Message) => void) {
	
	return (
		<tr key={msg.id} id={`row-${msg.id}`}>
			<td style={{ textAlign: "left" }}>
				<Link to={`/employeeView?id=${msg.userId}`}>{msg.user}</Link>
			</td>
			<td onClick={() => onClickRow(msg)} className="cursorPointer"><span className={styles.subject}>{msg.subject}</span> - {msg.body}</td>
			<td onClick={() => onClickRow(msg)} className="text-right cursorPointer">{formatTs(msg.timestamp)}</td>
			<td className="text-center">
				<ArchiveButton msg={msg} onClick={() => onClickArchive(msg)} />
			</td>
		</tr>
	);
}
function ArchiveButton(props: { msg: Message, onClick: (msg: Message) => void; }) {
	const archived = props.msg.archived;
	return (
		<button title={archived ? "Unarchive" : "Archive"} onClick={() => props.onClick(props.msg)} className="toggleButton">
			<Icon path={archived ? mdiArchiveOff : mdiArchive}
				size={1}
				color="black"
			/>
		</button>
	);
}

function formatTs(ts: number) {
	const now = new Date();
	const date = new Date(ts * 1000);

	const sameDate = now.getDate() === date.getDate() && now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear();

	let formatter: Intl.DateTimeFormat;
	if (sameDate)
		formatter = new Intl.DateTimeFormat("en", { hour: "2-digit", minute: "2-digit", hour12: true });
	else if (now.getFullYear() === date.getFullYear())
		formatter = new Intl.DateTimeFormat("es", { month: "short", day: "numeric" });
	else
		formatter = new Intl.DateTimeFormat("es", { year: "numeric", month: "short", day: "numeric" });

	return formatter.format(date);
}