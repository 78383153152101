import { Component, ChangeEvent, createRef } from "react";
import { CalendarSummarized, Employee, EmployeeBasicInfo, EmployeeExtended } from "../../../Api/ApiDefinition";
import style from "../index.module.scss";
export class EmployeeAddForm extends Component<EmployeeAddFormProps, EmployeeAddFormState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: EmployeeAddFormProps) {
        super(props);
        this.state = {
            userChanges: { 
                id: 0,
                prefix: "",
                name: "",
                surname: "",
                suffix: "",
                tags: [],
                calendar: { id: 1, name: "Fabrica Mañana" }
            }
        };
    }
    reset(): void {
        this.state = {
            userChanges: {
                id: 0,
                prefix: "",
                name: "",
                surname: "",
                suffix: "",
                tags: [],
                calendar: { id: 1, name: "Fabrica Mañana" }
            }
        };
    }
    //TODO Somehow expose userChanges to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const name = e.target.name as
            "prefix"
            | "name"
            | "surname"
            | "suffix"
            | "prefix";

        if (e.target.maxLength <= value.length) {
            alert("Max " + e.target.maxLength.toString() +" length exceeded");
            return;
        }
        const userChanges = this.state.userChanges;
        userChanges[name] = value;

        this.setState({ userChanges });
    }

    addUser(){
        const user: EmployeeBasicInfo = {
            id : 1212,
            name: this.state.userChanges.name,
            prefix: this.state.userChanges.prefix,
            surname: this.state.userChanges.surname, 
            suffix: this.state.userChanges.suffix,
            tags: [], 
            calendar: this.state.userChanges.calendar
        };
        this.reset();
        this.props.addUser(user);
        
    }
   

    componentDidUpdate(prevProps: EmployeeAddFormProps) {
        if (this.props.showEmployeeForm !== prevProps.showEmployeeForm) this.updateShown();
        
    }
    updateShown() {
        if (this.props.showEmployeeForm != false) {
            this.dialogRef.current?.showModal();

        } else if (this.props.showEmployeeForm == false) {
            this.dialogRef.current?.close();
        }
    }
    handleClosePopup() {
    
        this.reset();
        this.dialogRef.current?.close();
    }

    render() {
        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog key="addEmployee" className={style.employeeForm} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {

        if (this.props.showEmployeeForm == false) { return null; }
        return (
                <>
                <h1 className="head">Add Employee</h1>
                <div className="body">
                    <div className="form-group" style={{ flex: 1 }}>
                         <form  >
                            {this.UserInput("name", 50)}
                            <hr />
                            {this.UserInput("surname", 50)}
                            <hr />
                            {this.UserInput("suffix", 50)}
                            <hr />
                            {this.UserInput("prefix", 50)}
                            <hr />
                            <label className="label bold ">Calendars:  <br /></label>
                            {
                                this.props.calendars.map((calendar) => (
                                    <div className="mx05" key={calendar.id}>
                                        <label><input type="radio" className="text-muted" key={calendar.id} onClick={() => this.setState({ userChanges: { ...this.state.userChanges, calendar: calendar } })} value={calendar.id} name="calendar" onChange={this.handleChange.bind(this)}></input>
&nbsp;{calendar.name}</label>
                                    </div>
                                ))
                            }
                            </form>

                    </div>
                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.addUser()}>Add Employee</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.handleClosePopup()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof EmployeeBasicInfo, maxChars: number) {
        const userChanges = this.state.userChanges;

        const value = (userChanges[field]) as unknown as string;	//TODO validate field instead

        return (
            <div className={style.formInput}>
                <label className="label bold ">{field}</label>
                <input className="text-muted" maxLength={maxChars} value={value} name={field} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
}
interface EmployeeAddFormProps {
    showEmployeeForm: boolean;
    onDismiss: () => void;
    addUser: (user: EmployeeBasicInfo) => void;
    calendars: CalendarSummarized[];
}
interface EmployeeAddFormState { userChanges: EmployeeBasicInfo; }
