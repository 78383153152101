import Scheduler from "../../Components/Scheduler/Scheduler";
// import styles from './index.module.scss';

export default function TimeTablePage() {
	return (
		<section className="pageContent" style={{
			gap: "15px",
			display: "flex",
			flexDirection: "column",
		}}>
			<div className="page-header">
				<h2>Jornada Laboral</h2>
				<ol className="breadcrumb">
					<li className="active">Jornada Laboral</li>
				</ol>
			</div>

			<select>
				<option value="1">Horario 1</option>
				<option value="2">Horario 2</option>
				<option value="3">Horario 3</option>
				<option value="4">Horario 4</option>
				<option value="5">Horario 5</option>
				<option value="6">Horario 6</option>
			</select>

			<Scheduler></Scheduler>
		</section>
	);
}
