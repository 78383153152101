

import { WithTranslation, withTranslation } from "react-i18next";
import { STATUS, getApiInstance } from "../../../Api/ApiDefinition";
import { Component, createRef } from "react";
import styles from "../index.module.scss";


class EmployeeUpdatePhoto extends Component<EmployeeUpdatePhotoComponentProps> {
    api = getApiInstance();
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: EmployeeUpdatePhotoComponentProps) {
        super(props);

    }

  
    componentDidUpdate(prevProps: EmployeeUpdatePhotoComponentProps) {
        if (this.props.showEmployeeUpdatePhoto !== prevProps.showEmployeeUpdatePhoto) this.updateShown();
    }
    updateShown() {
        if (this.props.showEmployeeUpdatePhoto != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showEmployeeUpdatePhoto == false) {
            this.dialogRef.current?.close();
        }
    }
    

    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        const t = this.props.t;

        if (this.props.showEmployeeUpdatePhoto == false) { return null; }


        return (
            <>
                <h1 className="head">Select Photo</h1>
                <div className="body">
                    <p>Double click to select photo</p>
                    <div className="photo">
                        <img onDoubleClick={() => this.props.updatedPhoto("https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2.webp")} style={{ borderRadius: "50%" }} width={150} src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2.webp" />
                        <img onDoubleClick={() => this.props.updatedPhoto("https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1.webp")}  style={{ borderRadius: "50%" }} width={150}  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1.webp" />
                        <img onDoubleClick={() => this.props.updatedPhoto("https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp")}  style={{ borderRadius: "50%" }} width={150} src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp" />
                        <img onDoubleClick={() => this.props.updatedPhoto("https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4.webp")}  style={{ borderRadius: "50%" }} width={150} src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4.webp" />

                    </div>
                </div >
                <div className="footer">
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }


}

interface EmployeeUpdatePhotoComponentProps extends WithTranslation {
    showEmployeeUpdatePhoto: boolean;
    onDismiss: () => void;
    updatedPhoto: (image: string) => void;
}


export default withTranslation()(EmployeeUpdatePhoto);
