import { Component, ChangeEvent, createRef } from "react";
import { ScheduleSummarized } from "../../../Api/ApiDefinition";

export class ScheduleAddForm extends Component<ScheduleAddFormProps, ScheduleAddFormState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: ScheduleAddFormProps) {
        super(props);
        this.state = { scheduleChanges: {
            id: 0,
            name: "",
            weeklyWorkHours: 0
        } };
    }
    //TODO Somehow expose scheduleChanges to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const name = e.target.name as
            "name" | "weeklyWorkHours"
            ;
        const scheduleChanges = { ...this.state.scheduleChanges }; // Create a shallow copy
        scheduleChanges[name] = value;

        this.setState({ scheduleChanges });
    }



    componentDidUpdate(prevProps: ScheduleAddFormProps) {
        if (this.props.showScheduleAddForm !== prevProps.showScheduleAddForm) this.updateShown();
    }
    updateShown() {
        if (this.props.showScheduleAddForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showScheduleAddForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {

        if (this.props.showScheduleAddForm == false) { return null; }


        return (
            <>
                <h1 className="head">Add Schedule</h1>
                <div className="body">
                    <div style={{ flex: 1 }}>
                        <form className="body" >
                            {this.UserInput("name")}
                            <hr />
                            {this.UserInput("weeklyWorkHours")}
                        </form>

                    </div>
                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.addSchedule(this.state.scheduleChanges, 0)}>Save Changes</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof ScheduleSummarized) {
        const scheduleChanges = this.state.scheduleChanges;
        const value = (scheduleChanges[field]) as string | undefined;
        return (
            <div>
                <label className="label bold">{field}</label>
                <input className="text-muted" value={value || ""} name={field} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
}
interface ScheduleAddFormProps {
    showScheduleAddForm: boolean;
    onDismiss: () => void;
    addSchedule: (schedule: ScheduleSummarized, id: number) => void;

}
interface ScheduleAddFormState { scheduleChanges: ScheduleSummarized; }
