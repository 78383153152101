
// eslint-disable-next-line @typescript-eslint/prefer-namespace-keyword, @typescript-eslint/no-namespace
export class PlainWeek {
	private readonly internalDate: Temporal.PlainDate;

	static from(date: Temporal.PlainDate | "now" | string) {
		if (date === "now") date = Temporal.Now.plainDateISO();
		if (typeof date === "string") {
			if (date.match(/^\d{4}-W\d{1,2}$/)) date += "-1";	//Dash one, notably not minus one

			if (date.match(/^\d{4}-W\d{1,2}-\d$/)) {
				const [year, yearWeek, day] = date.replace("W", "").split("-").map((i) => parseInt(i));
				date = PlainWeek.parseISOWeekDate(year, yearWeek, day);
			} else {
				throw new Error("Invalid date format");
			}
		}

		date = date.subtract({ days: date.dayOfWeek - 2 });

		return new PlainWeek(date);
	}

	static parseISOWeekDate(iso_year: number, iso_week: number, iso_day: number) {
		function iso_year_start(iso_year: number) {
			const fourth_jan = new Temporal.PlainDate(iso_year, 1, 4);
			const delta = fourth_jan.dayOfWeek - 1;
			return fourth_jan.subtract({ days: delta });
		}
		const year_start = iso_year_start(iso_year);
		return year_start.add({ days: iso_day - 1, weeks: iso_week - 1 });
	}

	constructor(from: Temporal.PlainDate) {
		this.internalDate = from;
	}

	get days() {
		return Array.from({ length: 7 }, (_, i) => this.internalDate.add({ days: i }));
	}

	toISOString() {
		const date = this.internalDate;
		return `${date.yearOfWeek}-W${date.weekOfYear}`;
	}

	rangeString() {
		const formatter = new Intl.DateTimeFormat("es", { month: "long", day: "numeric" });
		const startStr = formatter.format(this.internalDate);
		const endStr = formatter.format(this.internalDate.add({ days: 6 }));

		if (this.internalDate.year != new Date().getFullYear())
			return `${this.internalDate.year}: ${startStr} - ${endStr}`;
		else
			return `${startStr} - ${endStr}`;
	}

	add(weeks: number) {
		const newDate = this.internalDate.add({ weeks });
		return new PlainWeek(newDate);
	}
	subtract(weeks: number) {
		const newDate = this.internalDate.subtract({ weeks });
		return new PlainWeek(newDate);
	}

}
