import React, { Component, createRef, useState } from "react";
import styles from "./AssignCalendar.module.scss";
import {  CalendarBasicInfo,  getApiInstance } from "../../../Api/ApiDefinition";

interface AssignCalendarModalProps {
	shownMessage: boolean;
	onDismiss: () => void;
	calendars: CalendarBasicInfo[];
	assignCalendar: (addCalendars: number) => void;
	
}


export class AssignCalendarModal extends Component<AssignCalendarModalProps, AssignCalendarState> {
	dialogRef = createRef<HTMLDialogElement>();
	api = getApiInstance();
	constructor(props: AssignCalendarModalProps) {
		super(props);
		this.state = {
			selectedValue: 0
		};
	}
	componentDidUpdate(prevProps: AssignCalendarModalProps) {
		if (this.props.shownMessage !== prevProps.shownMessage) this.updateShown();
	}
	updateShown() {
		if (this.props.shownMessage != false) {
			this.dialogRef.current?.showModal();
		} else if (this.props.shownMessage == false) {
			this.dialogRef.current?.close();
		}
	}
	selectCalendar = (id: number) => {
		this.setState({ selectedValue: id });
	};
	addCalendar = () => {
		this.props.assignCalendar(this.state.selectedValue);
		this.dialogRef.current?.close();
	};
	DialogBody() {
		if (this.props.shownMessage == false) { return null; }

		return (
			<>
				<h1 className="head">
					Select Calendar
				</h1>
				<div className="body">
					
					<div className={styles.modalsubline}><p>Select tags from the available list</p></div>
					<div>
						{
							this.props.calendars.map(calendar => <CalendarMap key={calendar.id} selectedCalendar={this.selectCalendar} calendar={calendar} isChecked={this.state.selectedValue} >{calendar.name}</CalendarMap>)
						}

					</div>
				</div>
				<div className="footer">
					<button className="btn btn-primary mr1" onClick={this.addCalendar}>Add Calendar </button>
					<button className="btn" onClick={() => this.dialogRef.current?.close()}>Cancel</button>
				</div>
			</>
		);
	}

	render() {
		if (this.dialogRef.current != null)
			this.dialogRef.current.onclose = this.props.onDismiss;

		return (
			<dialog ref={this.dialogRef}>
				{this.DialogBody()}
			</dialog>
		);
	}
}
interface AssignCalendarState { selectedValue: number; }

function CalendarMap(props: { children: string, calendar: CalendarBasicInfo, selectedCalendar: (id: number) => void; isChecked: number }): React.ReactElement {

	const handleDivClick = () => {
		props.selectedCalendar(props.calendar.id); // Call the function to handle selected tags
	};
	return (
		<div className={styles.calendarMap} onClick={handleDivClick}>
			<input type="radio" checked={props.isChecked == props.calendar.id} name="calendar" value={props.calendar.id} />
			{props.calendar.name}
		</div>
	);
}
