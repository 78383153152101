/* eslint-disable @typescript-eslint/no-namespace */
import { LocalApi } from "./LocalApi";

export function getApiInstance(type?: "test" | "prod"): ApiInstance {
	switch (type ?? "test") {
		case "test": {
			return new LocalApi();
		}
		case "prod": {
			return new LocalApi();
			//throw new Error("Not implemented");
		}
	}
}

export interface ApiInstance {
	getMessages(archived: boolean, keyword: string, pageNumber: number, perPage: number): AjaxCall<ApiResponses.getMessages>;
	setMessageArchived(id: Message["id"], archived: Message["archived"]): AjaxCall<ApiResponses.setMessageArchived>;


	getCalendars(): AjaxCall<ApiResponses.getCalendars>;
	getCalendar(id: Calendar["id"]): AjaxCall<ApiResponses.getCalendar>;
	postCalendar(id: number, data: ApiParams.postCalendar): AjaxCall<ApiResponses.postCalendar>;


	calendarAssignSchedule(data: ApiParams.calendarAssignSchedule): AjaxCall<ApiResponses.calendarAssignSchedule>;
	calendarUpdateSchedule(data: ApiParams.calendarUpdateSchedule): AjaxCall<ApiResponses.calendarUpdateSchedule>;
	calendarRemoveSchedule(id: CalendarSchedule["id"]): AjaxCall<ApiResponses.calendarRemoveSchedule>;

	calendarAssignVacations(data: ApiParams.calendarAssignVacations): AjaxCall<ApiResponses.calendarAssignVacations>;
	calendarUpdateVacations(data: ApiParams.calendarUpdateVacations): AjaxCall<ApiResponses.calendarUpdateVacations>;
	calendarRemoveVacations(id: CalendarVacations["id"]): AjaxCall<ApiResponses.calendarRemoveVacations>;

	calendarAssignHoliday(data: ApiParams.calendarAssignHoliday): AjaxCall<ApiResponses.calendarAssignHoliday>;
	calendarUpdateHoliday(data: ApiParams.calendarUpdateHoliday): AjaxCall<ApiResponses.calendarUpdateHoliday>;
	calendarRemoveHoliday(id: CalendarHoliday["id"]): AjaxCall<ApiResponses.calendarRemoveHoliday>;


	getSchedules(): AjaxCall<ApiResponses.getSchedules>;
	getSchedule(id: Schedule["id"]): AjaxCall<ApiResponses.getSchedule>;
	postSchedule(id: Schedule["id"], data: ApiParams.postSchedule): AjaxCall<ApiResponses.postSchedule>;


	getTimeSheet(week: string, tagsToShow: Employee["tags"][number][]): AjaxCall<ApiResponses.getTimeSheet>;
	putTimeSheetEntry(data: ApiParams.putTimeSheetEntry): AjaxCall<ApiResponses.postTimeSheetEntry>;
	patchTimeSheetEntry(id: Tag["id"], data: ApiParams.patchTimeSheetEntry): AjaxCall<ApiResponses.patchTimeSheetEntry>;
	exportTimeSheet(week: string): AjaxCall<ApiResponses.exportTimeSheet>;

	getTags(): AjaxCall<ApiResponses.getTags>;
	putTag(data: ApiParams.putTag): AjaxCall<ApiResponses.putTag>;
	patchTag(id: Tag["id"], data: ApiParams.patchTag): AjaxCall<ApiResponses.patchTag>;
	deleteTag(id: Tag["id"]): AjaxCall<ApiResponses.deleteTag>;


	getEmployees(): AjaxCall<ApiResponses.getEmployees>;
	getEmployee(id: Employee["id"]): AjaxCall<ApiResponses.getEmployee>;
	putEmployee(data: ApiParams.putEmployee): AjaxCall<ApiResponses.putEmployee>;
	patchEmployee(id: Employee["id"], data: ApiParams.patchEmployee): AjaxCall<ApiResponses.patchEmployee>;
	employeesSetTags(employeeIds: Employee["id"][], tagAddIds: number[], tagRemoveIds: number[]): AjaxCall<ApiResponses.employeesSetTags>;
	employeesSetCalendar(employeeIds: Employee["id"][], calendarId: Employee["calendar"]["id"]): AjaxCall<ApiResponses.employeesSetCalendar>;

	getEmployeeEvents(id: Employee["id"]): AjaxCall<ApiResponses.getEmployeeEvents>;
	getEmployeeWeekEvents(id: Employee["id"]): AjaxCall<ApiResponses.getEmployeeWeekEvents>;

	putEmployeeEvent(data: ApiParams.putEmployeeEvent): AjaxCall<ApiResponses.putEmployeeEvent>;
	patchEmployeeEvent(id: EmployeeEvent["id"], data: ApiParams.patchEmployeeEvent): AjaxCall<ApiResponses.patchEmployeeEvent>;
}
export namespace ApiResponses {
	export type getMessages = Message[];
	export type setMessageArchived = "OK";


	export type getCalendars = CalendarSummarized[];
	export type getCalendar = CalendarSummarized | null;
	export type postCalendar = CalendarSummarized;

	export type calendarAssignSchedule = CalendarSchedule;
	export type calendarUpdateSchedule = CalendarSchedule;
	export type calendarRemoveSchedule = "OK";

	export type calendarAssignVacations = CalendarVacations;
	export type calendarUpdateVacations = CalendarVacations;
	export type calendarRemoveVacations = "OK";

	export type calendarAssignHoliday = CalendarHoliday;
	export type calendarUpdateHoliday = CalendarHoliday;
	export type calendarRemoveHoliday = "OK";

	export type getSchedules = ScheduleSummarized[];
	export type getSchedule = ScheduleSummarized | null;
	export type postSchedule = ScheduleSummarized;

	export type getTimeSheet = ScheduleSummarized;
	export type postTimeSheetEntry = ScheduleSummarized;
	export type patchTimeSheetEntry = ScheduleSummarized;
	export type exportTimeSheet = "OK";


	export type getTags = Tag[];
	export type putTag = Tag;
	export type patchTag = Tag;
	export type deleteTag = "OK";


	export type getEmployees = EmployeeBasicInfo[];
	export type getEmployee = EmployeeExtended | null;
	export type putEmployee = EmployeeBasicInfo;
	export type patchEmployee = EmployeeBasicInfo;
	export type employeesSetTags = "OK";
	export type employeesSetCalendar = "OK";

	export type getEmployeeEvents = EmployeeCalendarEvent;
	export type getEmployeeWeekEvents = EmployeeEvent[];

	export type putEmployeeEvent = EmployeeEvent;
	export type patchEmployeeEvent = EmployeeEvent;
}
export namespace ApiParams {
	export type postCalendar = Omit<Calendar, "id" | "assignments" | "yearlyHours">;
	export type postSchedule = Omit<ScheduleSummarized, "id" | "periods">;
	export type calendarAssignSchedule = Omit<CalendarSchedule, "id">;
	export type calendarUpdateSchedule = Omit<CalendarSchedule, "calendarId" | "scheduleId">;
	export type calendarAssignVacations = Omit<CalendarVacations, "id">;
	export type calendarUpdateVacations = Omit<CalendarVacations, "calendarId">;
	export type calendarAssignHoliday = Omit<CalendarHoliday, "id">;
	export type calendarUpdateHoliday = Omit<CalendarHoliday, "calendarId">;
	export type putTag = Omit<Tag, "id">;
	export type patchTag = Partial<Omit<Tag, "id">>;
	export type putEmployee = Omit<EmployeeBasicInfo, "id">;
	export type patchEmployee = Partial<Omit<Employee, "id">>;

	export type putTimeSheetEntry = Partial<Omit<Shift, "id" | "start" | "end">>;
	export type patchTimeSheetEntry = Partial<Omit<Shift, "id" | "start" | "end">>;

	export type putEmployeeEvent = Omit<EmployeeEvent, "id">;
	export type patchEmployeeEvent = Partial<Omit<EmployeeEvent, "id">>;
}


export enum STATUS {
	OK,
	ERROR,
	HTTP_ERROR,
}

export type AjaxCall<T> = Promise<{
	status: STATUS.OK;
	data: T;
} | {
	status: STATUS.HTTP_ERROR;
	error: unknown;
}>;



export interface Shift {
	id: number;
	start: number;
	end: number | null;
	startEdited: number | null;
	endEdited: number | null;
	isDeleted: boolean;
	notes: string;
	confirmedEmployee: boolean;
	confirmedEmployer: boolean;
}


export interface Message {
	id: number;
	timestamp: number;
	subject: string;
	body: string;
	user: string;
	userId: number
	archived: boolean;
}


export interface Employee {
	id: number;
	prefix: string;
	name: string;
	surname: string;
	suffix: string;
	image: string | null;
	status: boolean;
	tags: Tag[];
	calendar: CalendarBasicInfo ;
	telephones: { kind: string, number: string; }[];
	emails: { kind: string, email: string; }[];

	address: string;
	notes: string;
}
export type EmployeeBasicInfo = Pick<Employee, "id" | "prefix" | "name" | "surname" | "suffix" | "tags" | "calendar">;
export interface Tag {
	id: number;
	text: string;
}

export interface EmployeeHourData {
	worked: {
		done: number;
		expected: number;
		yearly: number;
	},
	vacations: {
		used: number;
		assigned: number;
		yearly: number;
	};
}
export interface EmployeeExtended extends Employee {
	hourData: EmployeeHourData;
}
export type EmployeeCalendarEvent = {
	shift: Shift[],
	vacations: CalendarVacations[],
	holidays: CalendarHoliday[]
};


export type EmployeeEvent = [FaltaEvent, VacacionesEvent][number];

export interface FaltaEvent {
	id: number;
	tipo: "Falta";
	categoria: typeof CategoriasFalta[number];
	estado: typeof Estados[number];
	computo: typeof Computo[number];
	start: number;
	end: number;
}
export interface VacacionesEvent {
	id: number;
	tipo: "Vacaciones";
	categoria: "Vacaciones";
	estado: typeof Estados[number];
	computo: "Vacaciones";
	start: number;
	end: number;
}

export const CategoriasFalta = ["Otros", "Pendiente", "Deber Inexcusable", "Asuntos Propios", "Baja Medica"] as const;
export const Estados = ["Autorizada", "No autorizada", "PendienteIntervencion"] as const;
export const Computo = ["Derecho", "Concedida", "Falta"] as const;



export interface Calendar {
	/** Calendar ID */
	id: number;
	/** Calendar name */
	name: string;
	/** Yearly hour totals for work, holiday and vacations */
	yearlyHours: {
		work: number;
		holiday: number;
		vacations: number;
	};

	assignments: {
		work: CalendarSchedule[];
		holiday: CalendarHoliday[];
		vacations: CalendarVacations[];
	};
}
export interface CalendarSummarized extends Omit<Calendar, "assignments"> {
	/** First date of the calendar in seconds since epoch */
	firstDate: number | null;
	/** Last date of the calendar in seconds since epoch */
	lastDate: number | null;
	/** Assigned hours for the last, current and next years (Summarized calendar) */
	assignedHours: {
		current: {
			work: number;
			holiday: number;
			vacations: number;
		};
		next: {
			work: number;
			holiday: number;
			vacations: number;
		};
	};
	/** Number of employees assigned to this calendar */
	assignedEmployees: number;
}
export type CalendarBasicInfo = Pick<Calendar, "id" | "name">;

export interface CalendarSchedule {
	id: number;

	calendarId: Calendar["id"];
	scheduleId: Schedule["id"];

	start: number;
	end: number;
}
export interface CalendarVacations {
	id: number;

	calendarId: Calendar["id"];

	start: number;
	end: number;
}
export interface CalendarHoliday {
	id: number;
	calendarId: Calendar["id"];
	day: number;
}

export interface Schedule {
	/**
	 * The ID of the schedule.
	 */
	id: number;
	/**
	 * The name of the schedule.
	 */
	name: string;
	/**
	 * The periods of the schedule.
	 */
	periods: Tuple<unknown, 7>;
}
export interface ScheduleSummarized extends Omit<Schedule, "periods"> {
	weeklyWorkHours: number | string;
}
export type ScheduleBasicInfo = Pick<Schedule, "id" | "name">;