import { Component, ChangeEvent, createRef } from "react";
import { Shift } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class EmployeeEventAdd extends Component<EmployeeEventAddProps, EmployeeEventAddState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: EmployeeEventAddProps) {
        super(props);
        this.state = { eventChange: {} };
    }
    //TODO Somehow expose eventChange to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;
        const name = e.target.name as
            "startEdited"
        | "endEdited"
        | "notes";
        console.log(this.props);
        if (name == "startEdited" || name == "endEdited") {
            
            value = this.dateTimeToTimestamp( this.props.date, value);
        }
        // Cast eventChange as Partial<Shift>
        let eventChange = this.state.eventChange;
        console.log(value);
        // Check if the value is empty and handle the case accordingly
        if (value === "") {
           // delete eventChange[name]; // Remove the property if value is empty
        } else {
            console.log(eventChange[name]);
            eventChange = {
                ...eventChange,
                [name]: value
            };
           //eventChange[name] = value; // Assign the value otherwise
        }

        this.setState({ eventChange: eventChange });
    }



    componentDidUpdate(prevProps: EmployeeEventAddProps) {
        if (this.props.showEmployeeAddEventForm !== prevProps.showEmployeeAddEventForm) this.updateShown();
    }
    updateShown() {
        if (this.props.showEmployeeAddEventForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showEmployeeAddEventForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {
        if (this.props.showEmployeeAddEventForm == false) { return null; }
        return (
            <>
                <h1 className="head">Add Shift</h1>
                <div className="body" style={{ flex: 1 }}>
                    <form  >
                        {this.UserInput("startEdited")}
                        <hr />
                        {this.UserInput("endEdited")}
                        <hr />
                        {this.UserInput("notes")}
                    </form>

                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.addEmployeeEvent(this.state.eventChange)}>Save Changes</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof Shift) {
        const eventChange = this.state.eventChange;
        let value = (eventChange[field] ?? "") as unknown as string;	//TODO validate field instead
        console.log(value);
        let filedType = "time";
        if (field == "notes"){
            filedType = "text";
        }else{
            value = this.timestampToInputTime(value);
        }
        console.log(value);
        return (
            <div>
                <label className="label bold">{field}</label>
                <input className="text-muted" value={value} name={field} type={filedType} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
    dateTimeToTimestamp(dateString: string, timeString: string): string {
        const combinedDateTimeString = `${dateString}T${timeString}`;
        console.log(combinedDateTimeString);
        const dateTime = new Date(combinedDateTimeString);

        return dateTime.getTime().toString();
    }
    timestampToInputTime(timestamp: string): string {
        //console.log(timestamp);
        const date = new Date(Number(timestamp)); // Create a Date object from the timestamp
        const hours = String(date.getHours()).padStart(2, "0"); // Get hours and pad with leading zero if necessary
        const minutes = String(date.getMinutes()).padStart(2, "0"); // Get minutes and pad with leading zero if necessary
        return `${hours}:${minutes}`; // Format hours and minutes as HH:MM
    }
}
interface EmployeeEventAddProps {
    showEmployeeAddEventForm: boolean;
    onDismiss: () => void;
    addEmployeeEvent: (eventChange: Partial<Shift>) => void;
    date: string;

}
interface EmployeeEventAddState { eventChange: Partial<Shift>; }
