import { Component, ChangeEvent, createRef } from "react";
import { ScheduleSummarized } from "../../../Api/ApiDefinition";

export class ScheduleEditForm extends Component<ScheduleEditFormProps, ScheduleEditFormState>{
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: ScheduleEditFormProps) {
        super(props);
        this.state = { scheduleChanges: this.props.schedule};
    }
    //TODO Somehow expose scheduleChanges to the parent component

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const name = e.target.name as
            "name" | "weeklyWorkHours"
           ;
        console.log(e.target.name);
        const scheduleChanges = { ...this.state.scheduleChanges }; // Create a shallow copy

        scheduleChanges[name] = value;



        this.setState({ scheduleChanges });
    }



    componentDidUpdate(prevProps: ScheduleEditFormProps) {
        if (this.props.showScheduleEditForm !== prevProps.showScheduleEditForm) this.updateShown();
    }
    updateShown() {
        if (this.props.showScheduleEditForm != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showScheduleEditForm == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {

        if (this.props.showScheduleEditForm == false) { return null; }


        return (
            <>
                <h1 className="head">Edit Schedule</h1>
                <div className="body">
                    <div style={{ flex: 1 }}>
                        <form className="body" >
                            {this.UserInput("name")}
                           <hr/>
                            {this.UserInput("weeklyWorkHours")}
                        </form>

                    </div>
                </div >
                <div className="footer">
                    <button className="btn btn-primary" onClick={() => this.props.updateSchedule(this.state.scheduleChanges, this.props.schedule.id)}>Save Changes</button>&nbsp;&nbsp;
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
    UserInput(field: keyof ScheduleSummarized) {
        const scheduleChanges = this.state.scheduleChanges;
        const schedule = this.props.schedule;
        const value = (scheduleChanges[field] ?? schedule[field]) as string | undefined;	
        console.log(value);
        return (
            <div>
                <label className="label bold">{field}</label>
                <input className="text-muted" value={value || ""} name={field} onChange={this.handleChange.bind(this)}></input>
            </div>
        );

    }
}
interface ScheduleEditFormProps {
    schedule: ScheduleSummarized;
    showScheduleEditForm: boolean;
    onDismiss: () => void;
    updateSchedule: (schedule: ScheduleSummarized, id: number) => void;

}
interface ScheduleEditFormState { scheduleChanges: ScheduleSummarized; }
