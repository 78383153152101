import { Component, createRef } from "react";
import { EmployeeExtended } from "../../../Api/ApiDefinition";
import styles from "../index.module.scss";
export class EmployeeAbsences extends Component<EmployeeAbsencesProps, EmployeeAbsencesState> {
    dialogRef = createRef<HTMLDialogElement>();

    constructor(props: EmployeeAbsencesProps) {
        super(props);
        this.state = { userChanges: {} };
    }
   
    componentDidUpdate(prevProps: EmployeeAbsencesProps) {
        if (this.props.showEmployeeAbsences !== prevProps.showEmployeeAbsences) this.updateShown();
    }
    updateShown() {
        if (this.props.showEmployeeAbsences != false) {
            this.dialogRef.current?.showModal();
        } else if (this.props.showEmployeeAbsences == false) {
            this.dialogRef.current?.close();
        }
    }


    render() {

        if (this.dialogRef.current != null)
            this.dialogRef.current.onclose = this.props.onDismiss;

        return (
            <dialog className={styles.editEmployee} ref={this.dialogRef}>
                {this.DialogBody()}
            </dialog>
        );
    }
    DialogBody() {

        if (this.props.showEmployeeAbsences == false) { return null; }

        return (
            <>
                <h1 className="head">Employee Absences</h1>
                <div className="body" style={{ flex: 1 }}>
                    <table className="commonTable mt1">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>30/9/2021</td>
                                <td>Doctor Appointment</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>30/3/2024</td>
                                <td>Doctor Appointment</td>
                            </tr>
                        </tbody>

                    </table>

                </div >
                <div className="footer">
                    <button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
                </div>
            </>
        );
    }
}
interface EmployeeAbsencesProps {
    user: EmployeeExtended;
    showEmployeeAbsences: boolean;
    onDismiss: () => void;
}
interface EmployeeAbsencesState { userChanges: Partial<EmployeeExtended>; }
