import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // needed for dayClick
import { EmployeeEvent, EmployeeExtended, Shift, getApiInstance } from "../../../Api/ApiDefinition";
import { Component } from "react";
import { EmployeeEventEdit } from "./EmployeeEventEdit";
import { EmployeeEventAdd } from "./EmployeeEventAdd";



export class MonthCard extends Component<MonthCardProps, MonthCardState> {
	api = getApiInstance();

	constructor(props: MonthCardProps) {
		super(props);
		this.state = {
			showEmployeeEditEventForm: false,
			showEmployeeAddEventForm: false,
			formType: "shift",
			tooltipContent: "",
			tooltipVisible: false,
			event: {},
			date: "",
		};
		
	}
	handleEventMouseEnter = (arg: { event: { title: string; }; }) => {
		console.log("dddd");
		this.setState({ tooltipContent: arg.event.title });
		this.setState({ tooltipVisible: true });
	};

	handleEventMouseLeave = () => {
		this.setState({ tooltipContent: "" });
		this.setState({ tooltipVisible: false });
	};
	render() {
		return (
			<div className="card">
				<div className="body">
					<div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
						{Object.entries(eventTypesColors).map(([type, color]) => (
							<div key={type} style={{ marginRight: "10px", display: "flex" }}>
								<div style={{ width: "20px", height: "20px", backgroundColor: color }}></div>
								<span style={{ marginLeft: "5px" }}>{type}</span>
							</div>
						))}
					</div>
					<FullCalendar
						plugins={[dayGridPlugin, interactionPlugin]}
						height="auto"
						initialView="dayGridMonth"
						displayEventTime={false}
						displayEventEnd={false}
						showNonCurrentDates={false}
						fixedWeekCount={false}
						firstDay={1}
						headerToolbar={{
							left: "title",
							// center: "myCustomButton",
							right: "prev,next",
						}}
						dateClick={this.handleDateClick}
						events={[
							{
								start: "2024-02-29T00:00:00",
								end: "2024-03-02T24:00:00",
								allDay: true,
								backgroundColor: eventTypesColors["vacation"],
								title: "Festivos",
								description: "Festivos",
								data: "test",
								test:"Tst",
								display: "background",
								type: "vacation",

							},
							{
								start: "2024-04-06T08:00:00",
								end: "2024-04-06T24:00:00",
								backgroundColor: eventTypesColors["holiday"],
								title: "Horario Oficina",
								description: "Comienzo",
								className: "text-center",
								allDay: true,
								display: "background",
								type: "holiay",

							},
							{
								start: "2024-04-07T08:00:00",
								end: "2024-04-07T24:00:00",
								backgroundColor: eventTypesColors["holiday"],
								title: "Horario Oficina",
								description: "Comienzo",
								className: "text-center",
								allDay: true,
								display: "background",
								type: "holiay",

							},
							
							{
								start: "2024-04-02T08:00:00",
								end: "2024-04-02T16:15:00",
								backgroundColor: eventTypesColors["shift"],
								title: "8:00 AM - 4:15 PM",
								description: "8 hours - 15 mins",
								className: "text-left",
								allDay: false,
								type: "shift",
								note: "Test"

							},
							{
								start: "2024-04-03T08:10:00",
								end: "2024-04-03T16:15:00",
								backgroundColor: eventTypesColors["shift"],
								title: "8:10 AM - 4:15 PM",
								description: "8 hours - 5 mins",
								className: "text-left",
								allDay: false,
								type: "shift",
								note: "Test"

							},
							{
								start: "2024-04-08T09:10:00",
								end: "2024-04-08T17:45:00",
								backgroundColor: eventTypesColors["shift"],
								title: "9:10 AM - 5:45 PM",
								description: "8 hours - 35 mins",
								className: "text-left",
								allDay: false,
								type: "shift",
								note: "Test"

							},
						]}
						
						eventDidMount={(info) => {
							
							if (info.event.extendedProps.description && info.event.allDay == false) {
								
								info.el.querySelector(".fc-event-title")!.innerHTML += ("<br /><span>" + (info.event.extendedProps.description as string) + "</span>");
							}else{
								info.el.querySelector(".fc-event-title")!.innerHTML = "";
							}
						}}

						eventClick={(info) => {
							if (info.event.display == "background")
							{
								//
							}else{
								const startDateTime = new Date(info.event.startStr);
								const endDateTime = new Date(info.event.endStr);								
								this.setState({ formType: "shift" });
								this.setState({ event:  {
									startEdited: startDateTime.getTime(),
									endEdited: endDateTime.toDateString() == "Invalid Date" ? null : endDateTime.getTime(),
									notes: info.event.extendedProps.note as string,
								}});
								this.setState({ showEmployeeEditEventForm: true });
							}
							
						}}
						//eventMouseEnter={this.handleEventMouseEnter}
						//eventMouseLeave={this.handleEventMouseLeave}
					/>
					{this.state.tooltipVisible && (
						<div className="tooltip" style={{ position: "absolute", top: "", left: "0", background: "white", padding: "5px", border: "1px solid black" }}>
							{this.state.tooltipContent}
						</div>
					)}
					<EmployeeEventEdit event={this.state.event} showEmployeeEditEventForm={this.state.showEmployeeEditEventForm} updateEmployeeEvent={this.updateEmployeeEvent} onDismiss={() => this.setState({ showEmployeeEditEventForm: false })} />
					<EmployeeEventAdd showEmployeeAddEventForm={this.state.showEmployeeAddEventForm} addEmployeeEvent={this.addEmployeeEvent} onDismiss={() => this.setState({ showEmployeeAddEventForm: false })} date={this.state.date}/>
					
				</div>
			</div>
		);
	}
	handleDateClick = (arg: DateClickArg) => {
		// Check if there are events on the clicked date
		const clickedDate = arg.date;
		const eventsOnDate = arg.view.calendar.getEvents();
		const eventsOnClickedDate = eventsOnDate.filter(event => {
			// Extract date parts from event start and end dates
			const eventStartDate = new Date(event.startStr);
			const eventEndDate = new Date(event.endStr);
			const eventStartDateOnly = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate());
			const eventEndDateOnly = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate());

			// Extract date part from clicked date
			const clickedDateOnly = new Date(clickedDate.getFullYear(), clickedDate.getMonth(), clickedDate.getDate());

			// Compare the dates
			return eventStartDateOnly <= clickedDateOnly && eventEndDateOnly >= clickedDateOnly;
		});
		console.log(eventsOnClickedDate);
		if (eventsOnClickedDate.length > 0) {
			if (eventsOnClickedDate.length > 1 ){
				//
			}else{
				if (eventsOnClickedDate.length <= 1 ){
					this.setState({ formType: "vacation" });
					this.setState({ showEmployeeEditEventForm: true });
				}else{
					this.setState({ formType: "shift" });
					this.setState({ showEmployeeAddEventForm: true });
					this.setState({ date: clickedDate.getFullYear().toString() + "-" + this.padWithLeadingZero(clickedDate.getMonth()) + "-" + this.padWithLeadingZero(clickedDate.getDate()) });

					//this.setState({ date: clickedDate.getDate()});
				}
				
			}
			
			// Open the edit popup
			//this.setState({ showEmployeeEditEventForm: true });
			// Call your edit popup function here
		} else {
			console.log(clickedDate);
			// Open the create popup
			this.setState({ date: clickedDate.getFullYear().toString() + "-" + this.padWithLeadingZero(clickedDate.getMonth()) + "-" + this.padWithLeadingZero(clickedDate.getDate()) });
			this.setState({ showEmployeeAddEventForm: true });
			// Call your create popup function here
		}
	};
	padWithLeadingZero(value: number): string {
		return value < 10 ? "0" + value.toString() : value.toString();
	}
	updateEmployeeEvent =   (patchEmployeeEvent: Partial<Shift>) => {
		this.api.patchTimeSheetEntry(this.props.user.id, patchEmployeeEvent).then(
			() => {
				this.setState({ showEmployeeEditEventForm: false });

				alert("Shift Updated");
			}
		).catch(console.error);
		
	};
	addEmployeeEvent = (patchEmployeeEvent: Partial<Shift>) => {
		this.api.putTimeSheetEntry(patchEmployeeEvent).then(
			() => {
				this.setState({ showEmployeeAddEventForm: false });

				alert("Shift Add");
			}
		).catch(console.error);

		
	};
}
interface MonthCardProps {
	allCalendarEvents: EmployeeEvent[];
	user: EmployeeExtended;
	
}
interface MonthCardState { 
	showEmployeeEditEventForm: boolean;
	showEmployeeAddEventForm: boolean;
	formType: string;
	tooltipVisible: boolean;
	tooltipContent: string;
	event: Partial<Shift>;
	date: string;
}
const eventTypesColors = {
	vacation: "#4286f4", // Blue
	shift: "#2eff4e",    // Yellow
	holiday: "#ff7043"   // Orange
};