import { Component, createRef } from "react";


interface MessageModalProps {
	shownRecord: typeof exampleAttendanceRecord | null;
	onDismiss: () => void;
}

export const exampleAttendanceRecord = {
	shiftId: 1,
	User: {
		id: 1,
		name: "John Doe",
	},

	start: 1707207674,
	end: 1707245894,
	startEdited: 1707206400,
	endEdited: null,
	isDeleted: false,
	notesEmployee: "I forgot to sign when I came",
	notesEmployer: "",
	confirmedEmployee: true,
	confirmedEmployer: false,
	Pauses: [],
};

export class AttendanceRecordModal extends Component<MessageModalProps> {
	dialogRef = createRef<HTMLDialogElement>();

	componentDidUpdate(prevProps: MessageModalProps) {
		if (this.props.shownRecord !== prevProps.shownRecord) this.updateShown();
	}
	updateShown() {
		if (this.props.shownRecord != null) {
			this.dialogRef.current?.showModal();
		} else if (this.props.shownRecord == null) {
			this.dialogRef.current?.close();
		}
	}

	DialogBody() {
		if (this.props.shownRecord == null) { return null; }
		const record = this.props.shownRecord;

		return (
			<>
				<h1 className="head">
					Attendance Record
					<br />
					<small><a href="javascript:alert('Test')">{record.User.name}</a></small>
				</h1>
				<div className="body">
					
				</div>
				<div className="footer">
					<button className="btn" onClick={() => this.dialogRef.current?.close()}>Save Changes</button>
					<button className="btn" onClick={() => this.dialogRef.current?.close()}>Cancel</button>
				</div>
			</>
		);
	}

	render() {
		if (this.dialogRef.current != null)
			this.dialogRef.current.onclose = this.props.onDismiss;

		return (
			<dialog ref={this.dialogRef}>
				{this.DialogBody()}
			</dialog>
		);
	}
}
