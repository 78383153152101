import { Component } from "react";
import EmployeeListComponent from "./EmployeeListComponent";
import styles from "./index.module.scss";
import { CalendarSummarized, EmployeeBasicInfo, STATUS, Tag, getApiInstance } from "../../Api/ApiDefinition";
import { WithTranslation, withTranslation } from "react-i18next";
import { AssignTagModal } from "../../Components/CommonModals/AssignTags/AssignTags";
import { EmployeeAddForm } from "./Components/EmployeeAdd";
import { AssignCalendarModal } from "../../Components/CommonModals/AssignCalendar/AssignCalendar";

class EmployeeGroupsPage extends Component<EmployeeGroupsPageProps, EmployeeGroupsPageState> {
	api = getApiInstance();

	constructor(props: EmployeeGroupsPageProps) {
		super(props);

		this.state = { employees: [], selectedEmployee: [], showEmployeeForm: false, calendars: [], showAssignTagModal: false, tags: [], showAssignCalendarModal: false, showLoading : true};
	}

	componentDidMount() {
		void this.queryUsers();
		void this.getCalandar();
		void this.getTags();
	}
	getCalandar = async() => {
		await this.api.getCalendars().then(res => {
			if (res.status !== STATUS.OK)
				this.setState({ calendars: [] });
			else
				this.setState({ calendars: res.data });
		}).catch(err => {
			console.log(err);
		});
		
	};
	getTags = async () => {
		await this.api.getTags().then(res => {
			if (res.status !== STATUS.OK)
				this.setState({ tags: [] });
			else
				this.setState({ tags: res.data });
		}).catch(err => {
			console.log(err);
		});

	};
	async queryUsers() {
		this.setState({ showLoading: true });
		const employees = await this.api.getEmployees();

		if (employees.status !== STATUS.OK){
			this.setState({ employees: [] });
			this.setState({ showLoading: false });
		}else{
			this.setState({ employees: employees.data });
			this.setState({ showLoading: false });
		}
			

	}

	render() {
		const t = this.props.t;

		styles;	//Load it!

		return (
			<section className="pageContent" style={{ display: "flex", flexDirection: "column" }}>
				<div className="page-header">
					<h2>{t("employeeListPage.title")}</h2>
					<ol className="breadcrumb">
						<li className="active">{t("employeeListPage.title")}</li>
					</ol>
				</div>

				
				<div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<h3>{t("employeeListPage.employees")}</h3>
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<div style={{ marginRight: 10 }}>
							<ul className="topButtons">
								<li><button className="btn btn-primary" onClick={() => this.setState({ showEmployeeForm: true })}>{t("employeeListPage.addNew")}</button></li>
							</ul>
						
							
						</div>

					</div>
				</div>
				<EmployeeListComponent loading={this.state.showLoading} users={this.state.employees} handleRowClick={this.handleRowClick} />
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ marginRight: 10 }}>
						<ul className="bottomButtons">
							<li><button className="btn btn-primary" onClick={() => this.assignTagsPopup()}>{t("employeeListPage.setTag")}</button></li>
							<li><button className="btn btn-primary" onClick={() => this.setState({ showAssignCalendarModal: true })}>{t("employeeListPage.setCalendar")}</button></li>
						</ul>


					</div>

				</div>
				<AssignTagModal tags={this.state.tags} selectedEmployee={this.state.selectedEmployee} shownMessage={this.state.showAssignTagModal} assignTags={this.assignTags} onDismiss={() => this.setState({ showAssignTagModal: false })} />
				<AssignCalendarModal calendars={this.state.calendars} shownMessage={this.state.showAssignCalendarModal} assignCalendar={this.assignCalendar} onDismiss={() => this.setState({ showAssignCalendarModal: false })} />
				<EmployeeAddForm  calendars={this.state.calendars} showEmployeeForm={this.state.showEmployeeForm} addUser={this.addUser} onDismiss={() => this.setState({ showEmployeeForm: false })} />

			</section>
		);
	}
	assignTagsPopup = () => {

		this.setState({ showAssignTagModal: true });
	};
	handleRowClick = (id: EmployeeBasicInfo[]) => {
			this.setState({ selectedEmployee: id });
		
	};
	assignCalendar = (calendarId: number) =>{
		const selectedEmployeeIds = this.state.selectedEmployee.map((employee: { id: number }) => employee.id);

		this.api.employeesSetCalendar(selectedEmployeeIds, calendarId).then(
			void this.queryUsers()
		).catch(console.error);
	};
	assignTags = (addTags: number[], removeTags: number[])=>{
		const selectedEmployeeIds = this.state.selectedEmployee.map((employee: { id: number }) => employee.id);

		this.api.employeesSetTags(selectedEmployeeIds, addTags , removeTags).then(
			void this.queryUsers()
		).catch(console.error);
	};
	addUser = (user: EmployeeBasicInfo) => {
		this.api.putEmployee(user).then(
			() => {
				this.setState({ showEmployeeForm: false });
				void this.queryUsers();
			}
		).catch(console.error);
	};
}

interface EmployeeGroupsPageProps extends WithTranslation {
}
interface EmployeeGroupsPageState {
	employees: EmployeeBasicInfo[];
	selectedEmployee: EmployeeBasicInfo[];
	showEmployeeForm: boolean;
	calendars: CalendarSummarized[];
	showAssignTagModal: boolean;
	showAssignCalendarModal:boolean;
	tags: Tag[];
	showLoading: boolean;
}

export default withTranslation()(EmployeeGroupsPage);