import React from "react";
import { WeekView } from "../../Components/WeekView/WeekView";
import { WithTranslation, withTranslation } from "react-i18next";
import { AttendanceRecordModal, exampleAttendanceRecord } from "../../Components/CommonModals/AttendanceRecord/AttendanceRecord";
import { EmployeeEventAdd } from "../EmployeeView/Components/EmployeeEventAdd";
import { EmployeeEventEdit } from "../EmployeeView/Components/EmployeeEventEdit";
import { Shift, getApiInstance } from "../../Api/ApiDefinition";

class WeekPage extends React.Component<WeekPageProps, WeekPageState> {
	api = getApiInstance();

	constructor(props: WeekPageProps) {
		super(props);

		this.state = {
			recordShownModal: null,
			showEmployeeEditEventForm: false,
			showEmployeeAddEventForm: false,
			event: {},
			date: "",
			memberId: 0,
		};
	}

	render() {
		const t = this.props.t;

		const exampleRecord = exampleAttendanceRecord;

		return (
			<section className="pageContent">
				<div className="page-header">
					<h2>{t("weekPage.title")}</h2>
					<ol className="breadcrumb">
						<li className="active">{t("weekPage.title")}</li>
					</ol>
				</div>


				<WeekView attendanceAccessor={attendanceAccessor} onClickAttendanceRecord={this.onClickAttendanceRecord} />
				<EmployeeEventEdit event={this.state.event} showEmployeeEditEventForm={this.state.showEmployeeEditEventForm} updateEmployeeEvent={this.updateEmployeeEvent} onDismiss={() => this.setState({ showEmployeeEditEventForm: false })} />
				<EmployeeEventAdd showEmployeeAddEventForm={this.state.showEmployeeAddEventForm} addEmployeeEvent={this.addEmployeeEvent} onDismiss={() => this.setState({ showEmployeeAddEventForm: false })} date={this.state.date} />

				<AttendanceRecordModal shownRecord={this.state.recordShownModal} onDismiss={() => this.setState({ recordShownModal: null })} />
			</section>
		);
	}
	onClickAttendanceRecord = (msg: Shift, member: number) => {
		//
		console.log(msg);
		if (msg == undefined){
			
			this.setState({ showEmployeeAddEventForm: true, date: "2024-04-12" });

		}else{
			
			
			this.setState({
				showEmployeeEditEventForm: true,
				memberId: member, 
				event: {
					startEdited: msg.start*1000,
					endEdited: msg.end == null ? null : msg.end * 1000,
					notes: msg.notes,
				}
			});
			
		}
	};
	updateEmployeeEvent = (patchEmployeeEvent: Partial<Shift>) => {
		this.api.patchTimeSheetEntry(this.state.memberId, patchEmployeeEvent).then(
			() => {
				this.setState({ showEmployeeEditEventForm: false });

				alert("Shift Updated");
			}
		).catch(console.error);

	};
	addEmployeeEvent = (patchEmployeeEvent: Partial<Shift>) => {
		this.api.putTimeSheetEntry(patchEmployeeEvent).then(
			() => {
				this.setState({ showEmployeeAddEventForm: false });

				alert("Shift Add");
			}
		).catch(console.error);


	};
}

interface WeekPageProps extends WithTranslation {
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- //TODO
interface WeekPageState {
	recordShownModal: typeof exampleAttendanceRecord | null;
	showEmployeeAddEventForm: boolean;
	showEmployeeEditEventForm: boolean;
	event: Partial<Shift>;
	date: string;
	memberId: number
}


//TODO Mover a API

let fichajeId = 1;
function fichajes(week: string) {
	const fichajes: Shift[] = [];
	const monday = new Date();
	monday.setHours(0, 0, 0, 0);

	const start = new Date(monday);
	start.setDate(monday.getDate() - 14);

	//Start tiene que ser un lunes
	start.setDate(start.getDate() - start.getDay() + 7);

	for (const d = new Date(start); d < monday; d.setDate(d.getDate() + 1)) {
		//Me salto sabados y domingos
		if (d.getDay() === 0 || d.getDay() === 6) {
			continue;
		}

		//aleatoria entre las 7 y las 10
		const hora = Math.floor(Math.random() * 3) + 7;
		const minuto = Math.floor(Math.random() * 60);
		//Mas o menos 8 horas, entre 7 y 9
		const duracionFichaje = Math.round((8 + ((Math.random() - 0.5))) * 60 * 60);
		d.setHours(hora, minuto);
		fichajes.push({
			id: fichajeId++,
			start: d.getTime() / 1000,
			end: (d.getTime() / 1000) + duracionFichaje,
			startEdited: fichajeId%3 === 0 ? d.getTime() / 1000 : null,
			endEdited: fichajeId % 3 === 0 ? d.getTime() / 1000 : null,
			isDeleted: fichajeId % 10 === 0 ? true : false,
			notes: fichajeId % 4 === 0 ? "Lorem ipsum" : "",
			confirmedEmployee: true,
			confirmedEmployer: fichajeId % 4 === 0 ? true : false,
		});
	}

	return fichajes;

}
function attendanceAccessor(weeks: string[], employeeTags: unknown[]) {
	const returnVal: Record<string, MemberAttendance[]> = {};

	for (const week of weeks) {
		const members: MemberAttendance[] = [
			{
				records: fichajes(week),
				id: 1,
				tags: ["Test2", "Test1"],
				name: "Open shifts",
				imageUrl: "https://statics.teams.cdn.office.net/shifts/hashedassets/assets/8d4e92b8801e134cdab80f0aa7b42082.svg",
			},
			{
				records: fichajes(week),
				id: 2,
				tags: ["Test4", "Test1"],
				name: "Manuel Fernández de Tejada",
				imageUrl: "https://statics.teams.cdn.office.net/shifts/hashedassets/assets/8d4e92b8801e134cdab80f0aa7b42082.svg",
			},
			{
				records: fichajes(week),
				id: 3,
				tags: ["Test3", "Test2"],
				name: "Iker",
				imageUrl: "https://statics.teams.cdn.office.net/shifts/hashedassets/assets/8d4e92b8801e134cdab80f0aa7b42082.svg",
			},
			{
				records: fichajes(week),
				tags: ["Test1", "Test2"],
				id: 4,
				name: "Juanjo",
				imageUrl: "https://statics.teams.cdn.office.net/shifts/hashedassets/assets/8d4e92b8801e134cdab80f0aa7b42082.svg",
			},
		];

		returnVal[week] = members;
	}

	return new Promise<typeof returnVal>((resolve) => {
		setTimeout(() => {
			resolve(returnVal);
		}, 1000);
	});
}


export interface MemberAttendance {
	id: number,
	name: string,
	imageUrl: string,
	tags: string[],
	records: Shift[],
}
export interface Fichaje {
	id: number,
	start: number,
	end: number,
}
export default withTranslation()(WeekPage);
