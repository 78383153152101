import { Link } from "react-router-dom";
import "./NavigationMenu.css";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiAccount, mdiCalendar, mdiClipboardList, mdiClock, mdiHome, mdiHumanMaleBoard } from "@mdi/js";


export default function NavigationMenu(props: { className: string; }) {
	const { t: translate } = useTranslation("translation", { keyPrefix: "navMenu" });

	return (
		<nav className={props.className}>
			<ul>
				<NavItem route="/" icon={mdiHome}>
					{translate("home")}
				</NavItem>

				<NavItem route="/calendarList" icon={mdiClipboardList}>
					{translate("calendarList")}
				</NavItem>
				<NavItem route="/calendarView" icon={mdiCalendar}>
					{translate("calendarView")}
				</NavItem>
				<NavItem route="/timeTables" icon={mdiClock}>
					{translate("timeTables")}
				</NavItem>

				<NavItem route="/weekView" icon={mdiHumanMaleBoard}>
					{translate("weekView")}
				</NavItem>

				<NavItem route="/employeeList" icon={mdiAccount}>
					{translate("employeeList")}
				</NavItem>
				<NavItem route="/employeeView" icon={mdiAccount}>
					{translate("employeeView")}
				</NavItem>
			</ul>
			<SwapLangButton></SwapLangButton>
		</nav>
	);
}

function NavItem(props: { children: ReactNode, route: string, icon: string; }) {
	return (
		<Link to={props.route}>
			<li>
				<Icon path={props.icon} size="xl" />
				{props.children}
			</li>
		</Link>
	);
}

function SwapLangButton() {
	const { i18n } = useTranslation();
	const swapLang = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		const isEs = i18n.language === "es";
		void i18n.changeLanguage(isEs ? "en" : "es");
	};
	return (
		<button type="button" onClick={swapLang}>
			Lang
		</button>
	);
}