import { Component, createRef } from "react";

interface Message {
	title?: string;
	message: string;
}

interface MessageModalProps {
	shownMessage: Message | null;
	onDismiss: () => void;
}


export class MessageModal extends Component<MessageModalProps> {
	dialogRef = createRef<HTMLDialogElement>();

	componentDidUpdate(prevProps: MessageModalProps) {
		if (this.props.shownMessage !== prevProps.shownMessage) this.updateShown();
	}
	updateShown() {
		if (this.props.shownMessage != null) {
			this.dialogRef.current?.showModal();
		} else if (this.props.shownMessage == null) {
			this.dialogRef.current?.close();
		}
	}

	DialogBody() {
		if (this.props.shownMessage == null) { return null; }

		return (
			<>
				<h1 className="head">{this.props.shownMessage.title ?? ""}</h1>
				<div className="body">
					<p>{this.props.shownMessage.message}</p>
				</div>
				<div className="footer">
					<button className="btn" onClick={() => this.dialogRef.current?.close()}>Close</button>
				</div>
			</>
		);
	}

	render() {
		if (this.dialogRef.current != null)
			this.dialogRef.current.onclose = this.props.onDismiss;

		return (
			<dialog ref={this.dialogRef}>
				{this.DialogBody()}
			</dialog>
		);
	}
}