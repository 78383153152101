import { AjaxCall, ApiInstance, ApiParams, ApiResponses, Calendar, CalendarHoliday, CalendarSchedule, CalendarSummarized, CalendarVacations, Employee, EmployeeBasicInfo, EmployeeCalendarEvent, EmployeeEvent, Message, STATUS, ScheduleSummarized, Tag } from "./ApiDefinition";

const RESPONSE_DELAY = 1000;



export class LocalApi implements ApiInstance {
	async getMessages(archived: boolean, keyword: string ): AjaxCall<ApiResponses.getMessages> {
		await sleep();


		const randomToday = () => Math.trunc(new Date().getTime() / 1000 + ((Math.random() - 0.5) * 60 * 60));
		let idMessage = 1;
		const demoMessages: Message[] = [
			{
				id: idMessage++,
				timestamp: randomToday(), // Ejemplo de timestamp UNIX para una fecha y hora específica
				subject: "Olvidé fichar la entrada",
				body: "Hoy olvidé fichar mi entrada en la oficina. Llegué a las 9:30 AM.",
				user: "UsuarioA",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: randomToday(),
				subject: "Salida fuera de horario",
				body: "Tuve que salir de la oficina a las 4:45 PM para atender una emergencia personal.",
				user: "UsuarioB",
				userId: 1,
				archived: true,
			},
			{
				id: idMessage++,
				timestamp: randomToday(),
				subject: "Falta al trabajo",
				body: "No podré asistir al trabajo mañana debido a una enfermedad. Adjunto certificado médico.",
				user: "UsuarioC",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1683045600,
				subject: "Entrada inusual",
				body: "Hoy llegué a la oficina a las 7:00 AM en lugar de mi hora habitual. No se preocupe, es una excepción.",
				user: "UsuarioD",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1684349400,
				subject: "Olvidé fichar la salida",
				body: "Olvidé fichar mi salida de la oficina hoy. Salí a las 6:15 PM.",
				user: "UsuarioE",
				userId: 1,
				archived: true,
			},
			{
				id: idMessage++,
				timestamp: 1686155100,
				subject: "Falta al trabajo",
				body: "Lamento informar que no podré asistir al trabajo el próximo viernes debido a un compromiso familiar.",
				user: "UsuarioF",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1641004800,
				subject: "Salida fuera de horario",
				body: "Tuve que quedarme más tiempo en la oficina para completar una tarea urgente. Salí a las 8:30 PM.",
				user: "UsuarioG",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1648892100,
				subject: "Olvidé fichar la entrada",
				body: "Hoy nuevamente olvidé fichar mi entrada. Llegué a las 9:15 AM.",
				user: "UsuarioH",
				userId: 1,
				archived: true,
			},
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			}, 
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			}, 
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			}, 
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			},
			{
				id: idMessage++,
				timestamp: 1649855400,
				subject: "Falta al trabajo",
				body: "Debo faltar al trabajo el próximo martes debido a una cita médica programada.",
				user: "UsuarioI",
				userId: 1,
				archived: false,
			}
		];
		let messages: Message[] = [];
		if(archived){
			messages = demoMessages.filter(message => message.archived === true);

		}else{
			messages = demoMessages.filter(message => message.archived === false);
		}
		if (keyword != ""){
			messages = messages.filter(message => message.subject.toLowerCase().includes(keyword.toLowerCase()) || message.body.toLowerCase().includes(keyword.toLowerCase()));
		}
		return {
			status: STATUS.OK,
			data: messages,
		};
	}
	async setMessageArchived(id: Message["id"], archived: Message["archived"]): AjaxCall<ApiResponses.setMessageArchived> {
		await sleep();
		console.log({ id, archived });
		return {
			status: STATUS.OK,
			data: "OK"
		};
	}



	async getCalendars(): AjaxCall<ApiResponses.getCalendars> {
		await sleep();

		const demoItems: CalendarSummarized[] = [
			{
				id: 1,
				name: "Calendar 1",
				firstDate: 1630444800,
				lastDate: 1661980800,
				yearlyHours: {
					work: 1600,
					holiday: 200,
					vacations: 100,
				},
				assignedHours: {
					current: {
						work: 160,
						holiday: 25,
						vacations: 15,
					},
					next: {
						work: 170,
						holiday: 50,
						vacations: 70,
					},
				},
				assignedEmployees: 10,
			},
			{
				id: 2,
				name: "Calendar 2",
				firstDate: 1662067200,
				lastDate: 1693603200,
				yearlyHours: {
					work: 1500,
					holiday: 180,
					vacations: 90,
				},
				assignedHours: {
					current: {
						work: 155,
						holiday: 22,
						vacations: 12,
					},
					next: {
						work: 165,
						holiday: 28,
						vacations: 18,
					},
				},
				assignedEmployees: 8,
			},
			{
				id: 3,
				name: "Fresh Calendar",
				firstDate: null,
				lastDate: null,
				yearlyHours: {
					work: 1600,
					holiday: 200,
					vacations: 100,
				},
				assignedHours: {
					current: {
						work: 0,
						holiday: 0,
						vacations: 0,
					},
					next: {
						work: 0,
						holiday: 0,
						vacations: 0,
					},
				},
				assignedEmployees: 0,
			},

		];

		return {
			status: STATUS.OK,
			data: demoItems,
		};
	}
	async getCalendar(idCalendar: Calendar["id"]): AjaxCall<ApiResponses.getCalendar> {
		const items = await this.getCalendars();
		if (items.status != STATUS.OK) return items;
		const item = items.data.find((calendar) => calendar.id == idCalendar) ?? null;
		return {
			status: STATUS.OK,
			data: item
		};
	}
	async postCalendar(idCalendar: number, data: ApiParams.postCalendar): AjaxCall<ApiResponses.postCalendar> {
		await sleep();

		const item = {
			id: idCalendar == 0 ? 1 : idCalendar,
			...data,
			yearlyHours: {
				work: 0,
				holiday: 0,
				vacations: 0
			},
			firstDate: null,
			lastDate: null,
			assignedHours: {
				current: {
					work: 0,
					holiday: 0,
					vacations: 0
				},
				next: {
					work: 0,
					holiday: 0,
					vacations: 0
				}
			},
			assignedEmployees: 0
		};

		return {
			status: STATUS.OK,
			data: item,
		};
	}


	async getSchedules(): AjaxCall<ApiResponses.getSchedules> {
		await sleep();

		const demoItems: ScheduleSummarized[] = [
			{ id: 1, name: "Schedule 1", weeklyWorkHours: 40 },
			{ id: 2, name: "Schedule 2", weeklyWorkHours: 40 },
			{ id: 3, name: "Schedule 3", weeklyWorkHours: 40 },
		];

		return {
			status: STATUS.OK,
			data: demoItems,
		};
	}
	async getSchedule(id: number): AjaxCall<ApiResponses.getSchedule> {
		const items = await this.getSchedules();
		if (items.status != STATUS.OK) return items;
		const item = items.data.find((calendar) => calendar.id == id) ?? null;
		return {
			status: STATUS.OK,
			data: item
		};
	}
	async postSchedule(id: number, data: ApiParams.postSchedule): AjaxCall<ApiResponses.postSchedule> {
		await sleep();

		const schedule = {
			id: id == 0 ? 1 : id,
			...data,
			weeklyWorkHours: 40,
		};

		return {
			status: STATUS.OK,
			data: schedule,
		};
	}


	async getTimeSheet(week: string, tagsToShow: Employee["tags"][number][]): AjaxCall<ApiResponses.getTimeSheet> {
		await sleep();
		console.log(week, tagsToShow);
		throw new Error("Not Implemented!");
	}
	async putTimeSheetEntry(data: ApiParams.putTimeSheetEntry): AjaxCall<ApiResponses.postTimeSheetEntry> {
		await sleep();
		return {
			status: STATUS.OK,
			data: {
				...data,
				weeklyWorkHours: "",
				name: "",
				id: 0
			}
		};	
	}
	async patchTimeSheetEntry(id: Tag["id"], data: ApiParams.patchTimeSheetEntry): AjaxCall<ApiResponses.patchTimeSheetEntry> {
		await sleep();
		return {
			status: STATUS.OK,
			data: {
				id: id,
				...data,
				weeklyWorkHours: "",
				name: ""
			}
		};
		//throw new Error("Not Implemented!");
	}
	async exportTimeSheet(week: string): AjaxCall<"OK"> {
		await sleep();
		console.log(week);
		return {
			status: STATUS.OK,
			data: "OK",
		};
		//throw new Error("Not Implemented!");
	}

	async calendarAssignSchedule(data: ApiParams.calendarAssignSchedule): AjaxCall<ApiResponses.calendarAssignSchedule> {
		await sleep();
		return {
			status: STATUS.OK,
			data: {
				...data,
				id: 0
			}
		};

	}
	async calendarUpdateSchedule(data: ApiParams.calendarUpdateSchedule): AjaxCall<ApiResponses.calendarUpdateSchedule> {
		await sleep();
		console.log(data);
		throw new Error("Not implemented!");

	}
	async calendarRemoveSchedule(id: CalendarSchedule["id"]): AjaxCall<ApiResponses.calendarRemoveSchedule> {
		await sleep();
		console.log(id);
		throw new Error("Not implemented!");

	}

	async calendarAssignVacations(data: ApiParams.calendarAssignVacations): AjaxCall<ApiResponses.calendarAssignVacations> {
		await sleep();
		console.log(data);
		throw new Error("Not implemented!");

	}
	async calendarUpdateVacations(data: ApiParams.calendarUpdateVacations): AjaxCall<ApiResponses.calendarUpdateVacations> {
		await sleep();
		console.log(data);
		throw new Error("Not implemented!");

	}
	async calendarRemoveVacations(id: CalendarVacations["id"]): AjaxCall<ApiResponses.calendarRemoveVacations> {
		await sleep();
		console.log(id);
		throw new Error("Not implemented!");

	}

	async calendarAssignHoliday(data: ApiParams.calendarAssignHoliday): AjaxCall<ApiResponses.calendarAssignHoliday> {
		await sleep();
		console.log(data);
		throw new Error("Not implemented!");

	}
	async calendarUpdateHoliday(data: ApiParams.calendarUpdateHoliday): AjaxCall<ApiResponses.calendarUpdateHoliday> {
		await sleep();
		console.log(data);
		throw new Error("Not implemented!");

	}
	async calendarRemoveHoliday(id: CalendarHoliday["id"]): AjaxCall<ApiResponses.calendarRemoveHoliday> {
		await sleep();
		console.log(id);
		throw new Error("Not implemented!");

	}


	async getTags(): AjaxCall<ApiResponses.getTags> {
		await sleep();

		const demoItems: Tag[] = [
			{ id: 1, text: "Tag 1" },
			{ id: 2, text: "Tag 2" },
			{ id: 3, text: "Tag 3" },
		];

		return {
			status: STATUS.OK,
			data: demoItems,
		};
	}
	async putTag(data: ApiParams.putTag): AjaxCall<ApiResponses.putTag> {
		console.log(data);
		await sleep();
		return {
			status: STATUS.OK,
			data: {
				id: Math.floor(Math.random() * (1000 - 10)) + 10,
				text: data.text
			}
		};
		//throw new Error("Not Implemented");
	}
	async patchTag(id: Tag["id"], data: ApiParams.patchTag): AjaxCall<ApiResponses.putTag> {
		console.log(id, data);
		await sleep();
		throw new Error("Not Implemented");
	}
	async deleteTag(id: Tag["id"]): AjaxCall<ApiResponses.deleteTag> {
		console.log(id);
		await sleep();
		throw new Error("Not Implemented");
	}


	async getEmployees(): AjaxCall<ApiResponses.getEmployees> {
		await sleep();

		const demoItems: EmployeeBasicInfo[] = [
			{ id: 1, prefix: "001", name: "JUAN", surname: "FERNÁNDEZ", suffix: "", tags: [{ id: 1, text: "Tag 1"}, { id: 2, text: "Tag 2"}], calendar: { id: 1, name: "Fabrica Mañana" } },
			{ id: 2, prefix: "002", name: "MANUEL", surname: "MARTÍNEZ", suffix: "", tags: [{ id: 2, text: "Tag 2" },], calendar: { id: 1, name: "Fabrica Mañana" } },
			{ id: 3, prefix: "003", name: "CARLOS", surname: "DÍEZ", suffix: "", tags: [], calendar: { id: 1, name: "Fabrica Mañana" } },
		];

		return {
			status: STATUS.OK,
			data: demoItems,
		};
	}
	async getEmployee(id: number): AjaxCall<ApiResponses.getEmployee> {
		await sleep();
		console.log(id);
		return {
			status: STATUS.OK,
			data: {
				id: 1,
				tags: [], 
				prefix: "001",
				name: "JUAN",
				surname: "FERNÁNDEZ",
				suffix: "",
				calendar: { id: 1, name: "Fabrica Mañana" } ,
				image: null,
				address: "This is address",
				notes: "Es un poco vago",
				status: true,
				telephones: [{ kind: "default", number: "123456789" }],
				emails: [{ kind: "default", email: "example@gmail.com" }, { kind: "work", email: "work@gmail.com" }],
				hourData: {
					worked: {
						done: 0,
						expected: 0,
						yearly: 0
					},
					vacations: {
						used: 0,
						assigned: 0,
						yearly: 0
					}
				},
			}
		};
	}
	async putEmployee(data: ApiParams.putEmployee): AjaxCall<ApiResponses.patchEmployee> {
		await sleep();

		const item = {
			id: 1,
			...data,
		};

		return {
			status: STATUS.OK,
			data: item,
		};
	}
	async patchEmployee(id: number, data: ApiParams.patchEmployee): AjaxCall<ApiResponses.patchEmployee> {
		await sleep();

		const item = {
			id: id == 0 ? 1 : id,
			...data,
		};
		const base = await this.getEmployee(id);
		if (base.status != STATUS.OK) return base;

		return {
			status: STATUS.OK,
			data: { ...base.data!, ...item },
		};
	}
	async employeesSetTags(employeeIds: Employee["id"][], tagAddIds: number[], tagRemoveIds: number[]): AjaxCall<ApiResponses.employeesSetTags> {
		await sleep();
		console.log(employeeIds, tagAddIds, tagRemoveIds);
		return {
			status: STATUS.OK,
			data: "OK",
		};
	}
	async employeesSetCalendar(employeeIds: Employee["id"][], calendarId: Employee["calendar"]["id"]): AjaxCall<ApiResponses.employeesSetCalendar> {
		await sleep();
		console.log(employeeIds, calendarId);
		return {
			status: STATUS.OK,
			data: "OK",
		};
	}
	async getEmployeeWeekEvents(): AjaxCall<ApiResponses.getEmployeeWeekEvents> {
		await sleep();
		let idEvent = 1;

		const demoItems: EmployeeEvent[] = [
		{
			id: idEvent++,
			start: 1632960000,
			end: 1633046400,
			tipo: "Falta",
			categoria: "Asuntos Propios",
			estado: "Autorizada",
			computo: "Derecho"
		},
		{
			id: idEvent++,
			start: 1633161600,
			end: 1633248000,
			tipo: "Falta",
			categoria: "Baja Medica",
			estado: "Autorizada",
			computo: "Concedida"
		}
		];
		return {
			status: STATUS.OK,
			data: demoItems,
		};
		//throw new Error("Not Implemented!");
	}
	async getEmployeeEvents(): AjaxCall<ApiResponses.getEmployeeEvents> {
		await sleep();
		const demoItems: EmployeeCalendarEvent =  {
			shift: [
				{
					id: 1,
					start: 1712300804,
					end: null ,
					startEdited:  null,
					endEdited: null,
					isDeleted: false,
					notes: "",
					confirmedEmployee: false,
					confirmedEmployer: false
				},
				{
					id: 2,
					start: 1712214404,
					end: 1712203604,
					startEdited: null,
					endEdited: null,
					isDeleted: false,
					notes: "",
					confirmedEmployee: false,
					confirmedEmployer: false
				},
				{
					id: 3,
					start: 1712117204,
					end: 1712124404,
					startEdited: 1712124404,
					endEdited: 1712124404,
					isDeleted: false,
					notes: "",
					confirmedEmployee: false,
					confirmedEmployer: false
				},
				{
					id: 4,
					start: 1712038004,
					end: 1712016404,
					startEdited: null,
					endEdited: null,
					isDeleted: true,
					notes: "",
					confirmedEmployee: false,
					confirmedEmployer: false
				},
				{
					id: 5,
					start: 1712300804,
					end: null,
					startEdited: null,
					endEdited: null,
					isDeleted: true,
					notes: "Test",
					confirmedEmployee: false,
					confirmedEmployer: false
				}
			],
			vacations: [
				{
					id: 1,
					calendarId: 1,
					start: 1714003604,
					end: 1714435604,
				}
			],
			holidays: [
				{
					id: 1,
					calendarId: 1,
					day: 1712362004,
				},
				{
					id: 2,
					calendarId: 1,
					day: 1712448404,
				}
			]
		};
		return {
			status: STATUS.OK,
			data: demoItems,
		};
		//throw new Error("Not Implemented!");
	}
	async putEmployeeEvent(data: ApiParams.putEmployeeEvent): AjaxCall<ApiResponses.putEmployeeEvent> {
		console.log(data);
		await sleep();
		throw new Error("Not Implemented!");
	}
	async patchEmployeeEvent(id: EmployeeEvent["id"], data: ApiParams.patchEmployeeEvent): AjaxCall<ApiResponses.patchEmployeeEvent> {
		console.log(id, data);
		await sleep();
		throw new Error("Not Implemented!");
	}
}

function sleep(ms: number = RESPONSE_DELAY) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}