import React from "react";
import styles from "./index.module.scss";

interface Props {
	color?: React.CSSProperties["backgroundColor"];
	content?: string | React.Component | ((value: number) => React.Component | string);
	value: number;
	max: number;
}

const Progress: React.FC<Props> = (props) => {
	const per = Math.round((props.value / props.max) * 100);

	// Implement your component logic here

	return (
		<div className={styles.progress}>
			<div role="progressbar" style={{ width: per.toString()+"%" }}>
				<span>{Content(props.content, props.value)}</span>
			</div>
		</div >
	);
};

function Content(content: Props["content"], value: number): React.ReactNode {
	switch (typeof content) {
		case "string":
			return (<span>{content}</span>);
		case "function":
			return Content(content(value), value);
	}
}

export default Progress;