import { Routes, Route } from "react-router-dom";

import Layout from "./Layout/Layout";

import MessagesPage from "./Pages/MessageList/MessageListPage";

import CalendarListPage from "./Pages/CalendarList/CalendarListPage";
import CalendarViewPage from "./Pages/CalendarView/CalendarViewPage";
import TimeTablePage from "./Pages/ScheduleView/ScheduleViewPage";

import WeekPage from "./Pages/WeekPage/WeekPage";
import EmployeeGroupsPage from "./Pages/EmployeeList/EmployeeListPage";
import EmployeeViewPage from "./Pages/EmployeeView/EmployeeViewPage";

import NoMatchPage from "./Pages/NoMatchPage";

export default function Router() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<MessagesPage />} />
				<Route path="calendarList" element={<CalendarListPage />} />
				<Route path="calendarView" element={<CalendarViewPage />} />
				<Route path="timeTables" element={<TimeTablePage />} />
				<Route path="weekView" element={<WeekPage />} />
				<Route path="employeeList" element={<EmployeeGroupsPage />} />
				<Route path="employeeView" element={<EmployeeViewPage />} />
				<Route path="*" element={<NoMatchPage />} />
			</Route>
		</Routes>
	);
}
